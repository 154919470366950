<template>
    <div>
        <modal name="my-modal-daftar-ulang" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h4 class="card-header-title">
                            Keterangan Penolakan
                        </h4>
                        <!-- Close -->
                        <button type="button" class="close" @click="hide_modal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <input v-model="form.id_register" type="hidden">
                        <div class="card-body">
                            <div class="form-group">
                                <label>Keterangan</label>
                                <textarea v-model="form.message_reject" class="form-control" cols="30" rows="7" required></textarea>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal & Tutup</button>
                                <button @click.prevent="validasi_daftar_ulang_reject()" type="button" class="btn btn-primary mr-2">Simpan</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <div class="d-block">
            <div class="card-body">
                <div>
                    <div><b>Daftar Berkas Cicilan Siswa</b></div>
                    <span class="form-group mt-5">
                        <div v-if="detailregister.list_cicilan.length == 0 ">
                            <div class="alert alert-light shadow mb-4 mt-4">
                                <div class="d-flex align-items-center">
                                    <div>Belum menyelesaikan semua step registrasi.</div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <!-- form cicilan -->
                            <div class="border-top mt-2">
                                <div class="d-block pt-3">
                                    <div class="col-md-12 mb-md-0 mb-3">
                                        <div class="d-flex">
                                            <p v-html="detailregister.cicilan_deskripsi"></p>
                                        </div>
                                        <div class="d-flex">
                                            <label class="font-weight-bold">Total Pembayaran</label> : Rp. {{ formatPrice(detailregister.cicilan_total) }}
                                        </div>
                                        <div class="d-flex">
                                            <label class="font-weight-bold">Banyaknya Cicilan</label> : {{ detailregister.count_cicilan }}/{{ detailregister.cicilan_jumlah }} kali cicilan sudah dibayarkan
                                        </div>
                                        <hr>
                                        <div v-for="(item, index) in detailregister.list_cicilan" :key="index" >
                                            <div  class="alert alert-white shadow">
                                                <div class="row">
                                                    <div class="col-1">
                                                        <img src="https://scolacdn.com/frontend/argonne-img/class-icon-7.png" class="mt-1 sidebar-img-icon">
                                                    </div>
                                                    <div class="col-6">
                                                        Cicilan Tanggal {{ convertMount(item.date) }}
                                                        <span v-if="item.status == 2" class="ml-2 badge badge-success">Approve</span>
                                                        <span v-if="item.status == 3" class="ml-2 badge badge-danger">Re-check</span>
                                                        <h4>Rp. {{ formatPrice(item.cicilan) }}</h4> 
                                                        <h5 v-if="item.status != 0"><span class="fe fe-calendar"></span> {{ item.upload_date }}</h5>
                                                    </div>
                                                    <div class="text-right col-5">
                                                        <button type="button" v-if="item.status != 0" class="btn btn-sm btn-white" data-toggle="modal" v-bind:data-target="'#cekBuktiPembayaran'+item.id"><span class="fe fe-eye mt-1"></span> Lihat</button>
                                                        <!-- <button @click="confirmRegis(item.id, 2)" v-if="item.status == 1" class="ml-2 btn btn-sm btn-success"><span class="fe fe-eye mt-1"></span> Approve</button> -->
                                                        <button @click="confirmApprove(convertMount(item.date), item.id)" v-if="item.status == 1" class="ml-2 btn btn-sm btn-success"><span class="fe fe-eye mt-1"></span> Approve</button>
                                                        <button @click.prevent="reject_daftar_ulang(item.id)"  v-if="item.status == 1" class="ml-2 btn btn-sm btn-warning"><span class="fe fe-eye mt-1"></span> Re-Check</button>
                                                    </div>
                                                    <div class="modal fade" height="auto" v-bind:id="'cekBuktiPembayaran'+item.id" tabindex="-1" role="dialog" aria-hidden="true">
                                                        <div class="modal-dialog modal-dialog-centered" role="document">
                                                            <div class="modal-content">
                                                                <div class="modal-card card">
                                                                    <div class="card-header">
                                                                        <h4 class="card-header-title" id="exampleModalCenterTitle">
                                                                            Bukti Pembayaran
                                                                        </h4>
                                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>

                                                                    </div>
                                                                    <div class="card-body" style="height: 1000px;">
                                                                        <h5>Keterangan</h5>
                                                                        <p>{{ item.cicilan_note }}</p>
                                                                        <div class="mt-1">
                                                                            {{ item.atachment }}
                                                                            <img v-bind:src="item.attachment" alt="..." class="avatar-img rounded">
                                                                        </div>
                                                                    </div>
                                                                    <div class="card-footer border-top-0">
                                                                        <ul class="pagination mb-0"></ul>
                                                                        <div class="d-flex justify-content-end">
                                                                            <a @click.prevent="downloadImage(item.attachment,'BUKTI-CICILAN')" class="btn btn-sm btn-white d-none d-md-inline-block"> Download </a>
                                                                            <a href="#!" class="ml-2 btn btn-sm btn-white d-none d-md-inline-block" data-dismiss="modal" aria-label="Close"> Tutup</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        required
    } from "vuelidate/lib/validators";

    import moment from 'moment';

    import Vue from 'vue'
    import VueMask from 'v-mask'
    Vue.use(VueMask);

    import createNumberMask from 'text-mask-addons/dist/createNumberMask';
    const currencyMask = createNumberMask({
        prefix: '',
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
    });

    moment.locale('id');

    export default {
        data() {
            return {
                currencyMask,
                currentTabComponent: "StepTesting",
                baseUrl: this.$apiconfig,
                detaiPositon: {
                    step_registrasi_id: '',
                    status: '',
                    url: ''
                },
                fetching: true,
                detailregister: {
                    id: '',
                    kode_registrasi: '',
                    setup_formulir_id: '',
                    users_id: '',
                    fullname: '',
                    email: '',
                    phone: '',
                    status_pendaftaran: '',
                    status_pembayaran: '',
                    status_testing: '',
                    status_daftar_ulang: '',
                    status_cicilan: '',
                    cicilan_deskripsi: '',
                    cicilan_total: '',
                    cicilan_total_show: '',
                    cicilan_tanggal: '',
                    cicilan_jumlah: '',
                    gelombang_id: '',
                    formulir_id: '',
                    informasi_reg_ulang: '',
                    url: '',
                    count_cicilan: 0,
                    list_cicilan: []
                },
                dtStepDataberkas: [],
                form: {
                    id_register: '',
                    message_reject: '',
                    statusRejected: ''
                },
                sendbyEmail: true,
                statusSendbyEmail: '',
                status: {
                    statusPendaftaran: '',
                    statuSstep: '',
                },
            }
        },
        validations: {
            form: {
                deskripsiCicilan: {
                    required
                },
                totalCicilan: {
                    required
                },
                tanggalCicilan: {
                    required
                },
                jumlahCicilan: {
                    required
                },
            }
        },
        created() {
            this.getData();
        },
        methods: {
            hide_modal() {
                this.$modal.hide('my-modal-daftar-ulang')
            },
            getData() {
                this.statusSendbyEmail = "Iya";
                this.fetching = true;
                // GET STEP HISTORY & POSSION AKTIF
                this.$http.get(this.baseUrl + `admin/registrasi/data/positionstep?id=${this.$route.params.id}`)
                    .then((response) => {
                        this.detaiPositon.step_registrasi_id = response.data.data[0].step_registrasi_id;
                        this.detaiPositon.status = response.data.data[0].status;
                        this.detaiPositon.url = response.data.data[0].url;
                        this.currentTabComponent = response.data.data[0].url;
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                // GET DATA REGISTER
                this.$http.get(this.baseUrl + `admin/registrasi/data/detail?id=${this.$route.params.id}`)
                    .then((result) => {
                        this.detailregister.id = result.data.data[0].id; // tb Registrasi
                        this.detailregister.users_id = result.data.data[0].users_id; // tb Registrasi
                        this.detailregister.kode_registrasi = result.data.data[0].kode_registrasi; // tb Registrasi
                        this.detailregister.setup_formulir_id = result.data.data[0].setup_formulir_id; // tb Registrasi
                        this.detailregister.gelombang_id = result.data.data[0].gelombang_id; // tb Setup Formulir
                        this.detailregister.formulir_id = result.data.data[0].formulir_id; // tb Setup Formulir
                        this.detailregister.url_tes = result.data.data[0].url_tes; // tb Setup Formulir
                        this.detailregister.jadwal_tes = result.data.data[0].jadwal_tes; // tb Setup Formulir
                        this.detailregister.payment_gateway_information = result.data.data[0].payment_gateway_information; // tb Setup Formulir
                        this.detailregister.external_gateway_information = result.data.data[0].external_gateway_information; // tb Setup Formulir
                        this.detailregister.informasi_tes = result.data.data[0].informasi_tes; // tb Setup Formulir
                        this.detailregister.informasi_reg_ulang = result.data.data[0].informasi_reg_ulang; // tb Setup Formulir
                        this.detailregister.deskripsi_formulir = result.data.data[0].deskripsi_formulir; // tb Setup Formulir
                        this.detailregister.deskripsi_lulus = result.data.data[0].deskripsi_lulus; // tb Setup Formulir
                        this.detailregister.deskripsi_tidak_lulus = result.data.data[0].deskripsi_tidak_lulus; // tb Setup Formulir
                        this.detailregister.kode_formulir = result.data.data[0].kode_formulir; // tb Master Formulir
                        this.detailregister.nama_formulir = result.data.data[0].nama_formulir; // tb Master Formulir
                        this.detailregister.unit = result.data.data[0].unit; // tb Master Formulir
                        this.detailregister.keterangan = result.data.data[0].keterangan; // tb Master Formulir
                        this.detailregister.fullname = result.data.data[0].fullname; // tb Users
                        this.detailregister.email = result.data.data[0].email; // tb Users
                        this.detailregister.phone = result.data.data[0].phone; // tb Users
                        this.detailregister.status_cicilan = result.data.data[0].status_cicilan;
                        this.detailregister.cicilan_deskripsi = result.data.data[0].cicilan_deskripsi;
                        this.detailregister.cicilan_total = result.data.data[0].cicilan_total;
                        this.detailregister.cicilan_total_show = result.data.data[0].cicilan_total;
                        this.detailregister.cicilan_tanggal = result.data.data[0].cicilan_tanggal;
                        this.detailregister.cicilan_jumlah = result.data.data[0].cicilan_jumlah;
                        this.detailregister.list_cicilan = result.data.data[0].list_cicilan;
                        this.detailregister.count_cicilan = result.data.data[0].count_cicilan;
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                /// GET DATA FORMULIR DATA BERKAS
                this.$http.get(this.$apiconfig + `admin/registrasi/data/databerkas/daftar-ulang?registrasi_id=${this.$route.params.id}`)
                    .then((response) => {
                        this.dtStepDataberkas = response.data.data;
                        this.status.statusPendaftaran = response.data.statusPendaftaran;
                        this.status.statuSstep = response.data.statuSstep;
                        if (response.data.status == false) {
                            this.dtStepDataberkas = [];
                        }
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            // STEP DAFTAR ULANG
            reject_daftar_ulang(id) {
                this.$modal.show('my-modal-daftar-ulang');
                this.form.id_register = id;
            },
            validasi_daftar_ulang() {

                    var fd = new FormData()
                    fd.append('registrasi_id', this.detailregister.id);
                    fd.append('message_reject', this.form.message_reject);
                    fd.append('statusRejected', this.form.statusRejected);
                    fd.append('statusSendbyEmail', this.detailregister.statusSendbyEmail);
                    fd.append('statusCicilan', this.detailregister.status_cicilan);
                    fd.append('deskripsiCicilan', this.detailregister.cicilan_deskripsi);
                    fd.append('totalCicilan', this.detailregister.cicilan_total);
                    fd.append('tanggalCicilan', this.convertDate(this.detailregister.cicilan_tanggal));
                    fd.append('jumlahCicilan', this.detailregister.cicilan_jumlah);
                    if(this.detailregister.cicilan_jumlah > 0){
                        for (let i = 0; i < this.detailregister.cicilan_jumlah; i++) {
                            fd.append('listCicilan[]', this.detailregister.list_cicilan[i].cicilan);
                        }
                    }
                    this.$http({
                        url: this.baseUrl + 'admin/registrasi/validasi/daftar-ulang',
                        method: 'post',
                        data: fd
                    }).then((response) => {
                        if (response.data.status == true) {
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        } else {
                            this.$swal({
                                icon: 'info',
                                title: "info",
                                text: response.data.message,
                                showConfirmButton: true,
                            })
                        }
                        this.$modal.hide('my-modal-daftar-ulang')
                        this.detaiPositon.status = 'applied';
                        this.form.message_reject = '';
                        this.form.statusRejected = '';
                        this.getData();
                    }).catch(error => {
                        console.log(error.response.data)
                    })

            },
            validasi_daftar_ulang_reject() {
                var fd = new FormData()
                fd.append('status', 3);
                fd.append('id', this.form.id_register);
                fd.append('message', this.form.message_reject);
                this.$http({
                    url: this.baseUrl + 'admin/registrasi/validasi/confirm-cicilan',
                    method: 'post',
                    data: fd
                }).then((response) => {
                    if (response.data.status == true) {
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        } else {
                            this.$swal({
                                icon: 'info',
                                title: "info",
                                text: response.data.message,
                                showConfirmButton: true,
                            })
                        }
                        this.$modal.hide('my-modal-daftar-ulang')
                        this.form.message_reject = '';
                        this.getData();
                }).catch(error => {
                    console.log(error.response.data)
                })
            },
            generate_cicilan() {
                // this.list_cicilan = [];
                // for (let i = 0; i < this.detailregister.cicilan_jumlah; i++) {
                //     this.list_cicilan.push('0');
                // }

                var fd = new FormData()
                fd.append('registrasi_id', this.detailregister.id);
                fd.append('tanggalCicilan', this.convertDate(this.detailregister.cicilan_tanggal));
                fd.append('jumlahCicilan', this.detailregister.cicilan_jumlah);
                this.$http({
                    url: this.baseUrl + 'admin/registrasi/validasi/generate-cicilan',
                    method: 'post',
                    data: fd
                }).then((response) => {
                    if (response.data.status == true) {
                        this.getData();
                    } else {
                        this.getData();
                    }
                }).catch(error => {
                    console.log(error.response.data)
                })
                
            },
            // SEND EMAIL
            sendmail() {
                if (this.sendbyEmail == true) {
                    this.statusSendbyEmail = "Iya";
                } else {
                    this.statusSendbyEmail = "Tidak";
                }
            },
            download(url, filename) {
                fetch(url)
                    .then(response => response.blob())
                    .then(blob => {
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = filename;
                        link.click();
                    })
                    .catch(console.error);
            },
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
            },
            convertMount : function (date) {
                return moment(date, 'YYYY-MM-DD').format('DD MMMM YYYY'); 
            },
            confirmRegis(id, status) {
                var fd = new FormData()
                fd.append('status', status);
                fd.append('id', id);
                this.$http({
                    url: this.baseUrl + 'admin/registrasi/validasi/confirm-cicilan',
                    method: 'post',
                    data: fd
                }).then((response) => {
                    if (response.data.status == true) {
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: response.data.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        this.$swal({
                            icon: 'info',
                            title: "info",
                            text: response.data.message,
                            showConfirmButton: true,
                        })
                    }
                    this.getData();
                }).catch(error => {
                    console.log(error.response.data)
                })
                
            },
            confirmApprove(date, id){
                this.$swal({
                    title: 'Apakah anda yakin?',
                    text: "Akan approve Cicilan Tanggal " + date + "!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#1baa56',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ya, Approve!'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        var fd = new FormData()
                        fd.append('status', 2);
                        fd.append('id', id);
                        this.$http({
                            url: this.baseUrl + 'admin/registrasi/validasi/confirm-cicilan',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            if (response.data.status == true) {
                                this.$swal({
                                    icon: 'success',
                                    title: "success",
                                    text: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            } else {
                                this.$swal({
                                    icon: 'info',
                                    title: "info",
                                    text: response.data.message,
                                    showConfirmButton: true,
                                })
                            }
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                })
            },
            downloadImage(url, filename) {
                fetch(url)
                    .then(response => response.blob())
                    .then(blob => {
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = filename;
                        link.click();
                    })
                    .catch(console.error);
            },
            formatPrice(value) {
                let val = (value/1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
        },
    }
</script>