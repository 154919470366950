<template>
    <div>
        <!-- MODALS Keterangan Penolakan ================================================== -->
        <modal name="my-modal-daftar-ulang" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h4 class="card-header-title">
                            Keterangan Penolakan
                        </h4>
                        <!-- Close -->
                        <button type="button" class="close" @click="hide_modal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="card-body">
                            <div class="form-group">
                                <label>Status<span class="text-danger">*</span></label>
                                <select class="form-control" v-model="form.statusRejected" required>
                                    <option value="">Pilih</option>
                                    <option value="rejected">Rejected</option>
                                    <option value="re-check">Re-Check</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Keterangan</label>
                                <textarea v-model="form.message_reject" class="form-control" cols="30" rows="7" required></textarea>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal & Tutup</button>
                                <button @click.prevent="validasi_daftar_ulang_reject()" type="button" class="btn btn-primary mr-2">Simpan</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <div class="d-block">
            <div class="card-body">
                <div v-if="detaiPositon.url == 'tinjauan'">
                    <div v-html="detailregister.informasi_reg_ulang"></div>
                    <span class="form-group mt-5">
                        <div v-if="dtStepDataberkas.length == 0 || status.statuSstep == 'pending'">
                            <div v-if="status.statuSstep == 'pending'" class="alert alert-light shadow mb-4 mt-4">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 mr-4">
                                        <span class="fe fe-alert-triangle h2"></span>
                                    </div>
                                    <div>{{ status.statusPendaftaran }}.</div>
                                </div>
                            </div>
                            <div v-else class="alert alert-light shadow mb-4 mt-4">
                                <div class="d-flex align-items-center">
                                    <div>Belum menyelesaikan semua step registrasi.</div>
                                </div>
                            </div>
                        </div>
                        <form v-on:submit.prevent="validasi_daftar_ulang">
                        <div v-if="(dtStepDataberkas.length > 0 || status.statuSstep != 'pending') || status.statuSstep == 'pending'">
                            <!-- form description and upload -->
                            <div>
                                <a class="pt-2 d-inline-block mb-1">
                                    Deskripsi
                                </a>
                                <ckeditor  :disabled="detaiPositon.status == 'applied'" :name="'laporDeskripsi'" v-model="detailregister.lapor_deskripsi"  oninput="setCustomValidity('')"></ckeditor>
                                <a class="pt-2 d-inline-block mb-1">
                                    Upload lampiran
                                </a>
                                <div class="custom-file">
                                    <input type="file" :name="'file_attachment'" style="cursor:pointer !important" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" @change="previewFiles($event)" accept="image/x-png,image/gif,image/jpeg,.pdf,.doc,.docx,.xlx,.xlsx">
                                    <label class="custom-file-label" for="inputGroupFile01">
                                    <span v-if="detailregister.lapor_file != null && detailregister.lapor_file != ''">{{ detailregister.lapor_file.length > 100 ? detailregister.lapor_file.substr(0,110) + '...' : detailregister.lapor_file }}</span>
                                    <span v-else>Choose file</span>
                                    </label>
                                </div>
                                <div class="d-none mt-3" :id="'file-uploaded-section'">
                                    <div class="mb-3">
                                        <div class="file-uploaded-area" :id="'file-uploaded-area'"></div>
                                    </div>
                                </div>
                                <div class="mt-3" :id="'file-uploaded-section-before'">
                                    <div class="mb-3">
                                        <div class="file-uploaded-area" v-bind:style="{ 'background-image': 'url(' + detailregister.lapor_file + ')' }"></div>
                                    </div>
                                </div>

                                <progress class="d-none" :id="'upload-bar'" max="100" :value.prop="uploadPercentage" style="width:100%">Uploading </progress>
                                    
                            </div>
                            <div class="pb-3">
                                <div class="d-flex align-items-center justify-content-between d-flex-mobile">
                                    <div class="d-block p-3">
                                    </div>
                                    <div>
                                        <a v-if="detailregister.lapor_file_prev != null && detailregister.lapor_file_prev != '' && detailregister.extension != 'png' && detailregister.extension != 'jpg' && detailregister.extension != 'jpeg' && detailregister.extension != 'webp'" :href="detailregister.lapor_file_prev" target="_blank" type="button" class="btn btn-white mr-2 px-md-4">Lihat Lampiran Sebelumnya<span class="fe fe-file ml-2"></span></a>
                                        <button :id="'submit-button'" v-if="status.statuSstep == 'applied' || status.statuSstep == 'pending'" v-on:click="uploadFileAttachment()" type="button" class="btn btn-primary mr-2 px-md-4">
                                            {{ status.statuSstep == 'applied' ? 'Perbaharui' : 'Simpan' }}
                                        </button>
                                        <button disabled :id="'progress-button'" type="button" class="btn btn-primary d-none">Uploading {{uploadPercentage}}%...</button>
                                    </div>
                                </div>
                            </div>
                            <!-- form description and upload -->
                        </div>
                        <div v-if="dtStepDataberkas.length > 0 && status.statuSstep != 'pending'">
                            <div class="card mb-2 shadow-sm" v-for="(berkas_list, index) in dtStepDataberkas" :key="index">
                                <div class="card-body py-3">
                                    <div class="row">
                                        <div class="col materi-list materi-list--pdf">
                                            <div class="d-flex align-items-center">
                                                <div class="mb-0">
                                                    <a class="d-inline-block mb-1">
                                                        {{ berkas_list.label_name }}
                                                    </a>
                                                    <div class="d-flex align-items-center">
                                                        <small class="d-block  mr-3"><span class="fe fe-calendar mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Tanggal mengumpulkan"></span>{{ berkas_list.created_at }}</small>
                                                        <span class="badge badge-light mr-3"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <div class="btn-group" role="group">
                                                <a data-toggle="modal" v-bind:data-target="'#cekBuktiberkasdafulang'+berkas_list.id" class="btn btn-sm btn-white btn-block">
                                                    <span class="fe fe-eye mr-2"></span>lihat
                                                </a>
                                                <div class="modal fade" height="auto" v-bind:id="'cekBuktiberkasdafulang'+berkas_list.id" tabindex="-1" role="dialog" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-card card">
                                                                <div class="card-header">
                                                                    <h4 class="card-header-title" id="exampleModalCenterTitle">
                                                                        Berkas
                                                                    </h4>
                                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>

                                                                </div>
                                                                <div class="card-body" style="height: 1000px;">
                                                                    <div v-if="berkas_list.file_attachment.split('.').pop() == 'pdf'" style="height: 100%">
                                                                        <embed v-bind:src="berkas_list.file_attachment" alt="..." class="avatar-img rounded">
                                                                    </div>
                                                                    <div v-else>
                                                                        <img v-bind:src="berkas_list.file_attachment" alt="..." class="avatar-img rounded">
                                                                    </div>
                                                                </div>
                                                                <div class="card-footer border-top-0">
                                                                    <ul class="pagination mb-0"></ul>
                                                                    <div class="d-flex justify-content-end">
                                                                        <a @click.prevent="download(berkas_list.file_attachment,'BUKTI-BAYAR')" class="btn btn-sm btn-white d-none d-md-inline-block"> Download </a>
                                                                        <a href="#!" class="btn btn-sm btn-white d-none d-md-inline-block" data-dismiss="modal" aria-label="Close"> Tutup</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="status.statuSstep == 'waiting'" class="alert alert-warning shadow mb-4 mt-4">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 mr-4">
                                        <span class="fe fe-alert-triangle h2"></span>
                                    </div>
                                    <div>{{ status.statusPendaftaran }}.</div>
                                </div>
                            </div>
                            <div v-if="status.statuSstep == 're-check'" class="alert alert-info shadow mb-4 mt-4">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 mr-4">
                                        <span class="fe fe-alert-triangle h2"></span>
                                    </div>
                                    <div>{{ status.statusPendaftaran }}.</div>
                                </div>
                            </div>
                            <div v-if="status.statuSstep == 'rejected'" class="alert alert-danger shadow mb-4 mt-4">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 mr-4"><span class="fe fe-x-circle h2 m-0"></span></div>
                                    <div> {{ status.statusPendaftaran }}.</div>
                                </div>
                            </div>
                            <div v-if="status.statuSstep == 'applied'" class="alert alert-success shadow mb-4 mt-4">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 mr-4"><span class="fe fe-check-circle h2 m-0"></span></div>
                                    <div> {{ status.statusPendaftaran }}.</div>
                                </div>
                            </div>
                            <!-- form cicilan -->
                            <div v-if="$beta_cicilan" class="border-top mt-2">
                                <div class="d-block p-4">
                                    <div class="d-flex align-items-center justify-content-between d-flex-mobile">
                                        <div class="d-block pb-2 rounded">
                                            <div class="d-flex align-items-center">
                                                <label class="mr-4 font-weight-bold my-0">Apakah Siswa ini akan diberikan cicilan pembayaran sekolah ? </label>
                                            </div>
                                            <div class="mt-3 d-flex align-items-center">
                                                <div>
                                                    <input v-model="detailregister.status_cicilan" type="radio" id="status_catatan" name="status_catatan" :value="1" :disabled="detaiPositon.status == 'applied'">
                                                    <label class="mr-4 font-weight-bold my-0 ml-2">Iya</label>
                                                    <input v-model="detailregister.status_cicilan" type="radio" id="status_catatan" name="status_catatan" :value="0" :disabled="detaiPositon.status == 'applied'">
                                                    <label class="mr-4 font-weight-bold my-0 ml-2">Tidak</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="detailregister.status_cicilan == 1" class="p-3 m-1 card bg-light row row-sm">
                                        <div class="col-md-12 mb-md-0 mb-3">
                                            <div class="d-flex">
                                                <div class="form-group mb-4 w-100">
                                                    <label class="font-weight-bold">Deskripsi Cicilan<span class="text-danger">*</span></label>
                                                    <ckeditor v-model="detailregister.cicilan_deskripsi" :disabled="detaiPositon.status == 'applied'" required="" oninvalid="this.setCustomValidity('Data Wajib Diisi')" oninput="setCustomValidity('')"></ckeditor>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div class="form-group mb-4 w-100">
                                                    <label class="font-weight-bold">Upload Lampiran</label>
                                                    <div class="custom-file">
                                                        <input type="file" :name="'file_cicilan'" style="cursor:pointer !important" class="custom-file-input" id="inputGroupFile01Cicilan" aria-describedby="inputGroupFileAddon01" @change="previewFilesCicilan($event)" accept="image/x-png,image/gif,image/jpeg">
                                                        <label class="custom-file-label" for="inputGroupFile01Cicilan">
                                                        <span v-if="detailregister.cicilan_file != null && detailregister.cicilan_file != ''">{{ detailregister.cicilan_file.length > 100 ? detailregister.cicilan_file.substr(0,110) + '...' : detailregister.cicilan_file }}</span>
                                                        <span v-else>Choose file</span>
                                                        </label>
                                                    </div>
                                                    <div class="d-none mt-3" :id="'file-uploaded-section-cicilan'">
                                                        <div class="mb-3">
                                                            <div class="file-uploaded-area file-area-cicilan" :id="'file-uploaded-area-cicilan'"></div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-3" :id="'file-uploaded-section-before-cicilan'">
                                                        <div class="mb-3">
                                                            <div class="file-uploaded-area file-area-cicilan" v-bind:style="{ 'background-image': 'url(' + detailregister.cicilan_file + ')' }"></div>
                                                        </div>
                                                    </div>

                                                    <progress class="d-none" :id="'upload-bar-cicilan'" max="100" :value.prop="uploadPercentageCicilan" style="width:100%"></progress>
                                                    <button :id="'upload-button-cicilan'" v-on:click="uploadFileCicilan()" type="button" class="btn btn-primary mt-3 d-none"><i class="fe fe-upload"></i> Upload</button>
                                                    <button disabled :id="'progress-button-cicilan'" type="button" class="btn btn-primary mt-3 d-none">Uploading {{uploadPercentageCicilan}}%...</button>
                                                 
                                                </div>
                                            </div>
                                            <div v-if="detailregister.cicilan_file_prev != null && detailregister.cicilan_file_prev != '' && detailregister.cicilan_ext != 'png' && detailregister.cicilan_ext != 'jpg' && detailregister.cicilan_ext != 'jpeg' && detailregister.cicilan_ext != 'webp'" class=" pt-2">
                                                <div class="d-flex align-items-center justify-content-between d-flex-mobile">
                                                    <div class="d-block p-3">
                                                    </div>
                                                    <div>
                                                        <a  :href="detailregister.cicilan_file_prev" target="_blank" type="button" class="btn btn-white mr-2 px-md-4">Lihat Lampiran Sebelumnya<span class="fe fe-file ml-2"></span></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div class="form-group mb-4 w-100">
                                                    <label class="font-weight-bold">Total Pembayaran<span class="text-danger">*</span></label>
                                                    <input type="text" v-mask="currencyMask" class="form-control" required="" :disabled="detaiPositon.status == 'applied'"
                                                        v-model="detailregister.cicilan_total" oninvalid="this.setCustomValidity('Data Wajib Diisi')" oninput="setCustomValidity('')">
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div class="form-group mb-4 mr-2 w-100">
                                                    <label class="font-weight-bold">Tanggal Jatuh Tempo<span class="text-danger">*</span></label>
                                                    <datepicker  :input-class="'form-control'" required=""
                                                        v-model="detailregister.cicilan_tanggal" placeholder="(YYYY-mm-dd)" :disabled="detaiPositon.status == 'applied'" oninvalid="this.setCustomValidity('Data Wajib Diisi')" oninput="setCustomValidity('')"></datepicker>
                                                    <!-- <input v-if="detaiPositon.status == 'applied'" type="text" disabled class="form-control" :value="convertMount(detailregister.cicilan_tanggal)"> -->
                                                </div>
                                                <div class="form-group mb-4 ml-2 w-100">
                                                    <label class="font-weight-bold">Jumlah Cicilan<span class="text-danger">*</span></label>
                                                    <input type="number" class="form-control"
                                                        v-model="detailregister.cicilan_jumlah" :disabled="detaiPositon.status == 'applied'" required="" oninvalid="this.setCustomValidity('Data Wajib Diisi')" oninput="setCustomValidity('')">
                                                </div>
                                            </div>
                                            <button type="button" @click.prevent="generate_cicilan()" v-if="detaiPositon.status != 'applied'" :disabled="detailregister.cicilan_tanggal == '' || detailregister.cicilan_jumlah == '' " class="col-12 btn btn-primary">Generate Cicilan</button>
                                            <hr>
                                            <div class="mt-3 row">
                                                 <div class="form-group col-6 w-100" v-for="(item, index) in detailregister.list_cicilan" :key="index" >
                                                    <label class="font-weight-bold">Cicilan Tanggal {{ convertMount(item.date) }}<span class="text-danger">*</span></label>
                                                    <input type="text" v-mask="currencyMask" class="form-control" required="" :disabled="detaiPositon.status == 'applied'"
                                                        v-model="item.cicilan" oninvalid="this.setCustomValidity('Data Wajib Diisi')" oninput="setCustomValidity('')">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- -->
                            <div v-if="detaiPositon.status == 'pending' || detaiPositon.status == 'waiting' || detaiPositon.status == 're-check'" class="border-top pt-2">
                                <div class="d-flex align-items-center justify-content-between d-flex-mobile">
                                    <div class="d-block p-3 bg-light rounded">
                                        <div class="d-flex align-items-center">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" id="switchOne" v-model="sendbyEmail" @change="sendmail()" class="custom-control-input">
                                                <label for="switchOne" class="custom-control-label"></label>
                                            </div>
                                            <label class="font-weight-bold my-0 ml-2">Kirim Notifikasi Email</label>
                                        </div>
                                    </div>
                                    <div>
                                        <button id="submit-save" type="submit" class="btn btn-primary mr-2 px-md-4">
                                            Lolos
                                        </button>
                                        <button disabled :id="'progress-button-submit'" type="button" class="btn btn-primary d-none mr-2 px-md-4">Uploading {{uploadPercentage}}%...</button>
                                        <!-- <button @click.prevent="validasi_daftar_ulang()" type="button" class="btn btn-primary mr-2 px-md-4">
                                            Lolos
                                        </button> !-->
                                        <button @click.prevent="reject_daftar_ulang()" type="button" class="btn btn-danger px-md-4">
                                            Tidak Lolos
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                            </div>
                        </div>
                        </form>
                    </span>
                </div>
                <div v-else>
                    <div class="alert alert-light shadow m-0">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 mr-4">
                                <span class="fe fe-alert-triangle h2 m-0"></span>
                            </div>
                            <div>
                                Calon peserta belum menyelesaikan semua step registrasi
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        required
    } from "vuelidate/lib/validators";

    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment';

    import Vue from 'vue'
    import VueMask from 'v-mask'
    Vue.use(VueMask);

    import createNumberMask from 'text-mask-addons/dist/createNumberMask';
    const currencyMask = createNumberMask({
        prefix: '',
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
    });

    moment.locale('id');

    export default {
        components : {
            Datepicker
        },
        data() {
            return {
                currencyMask,
                currentTabComponent: "StepTesting",
                baseUrl: this.$apiconfig,
                detaiPositon: {
                    step_registrasi_id: '',
                    status: '',
                    url: ''
                },
                file_attachment: '',
                fetching: true,
                detailregister: {
                    id: '',
                    kode_registrasi: '',
                    setup_formulir_id: '',
                    users_id: '',
                    fullname: '',
                    email: '',
                    phone: '',
                    status_pendaftaran: '',
                    status_pembayaran: '',
                    status_testing: '',
                    status_daftar_ulang: '',
                    status_cicilan: '',
                    cicilan_deskripsi: '',
                    cicilan_total: '',
                    cicilan_total_show: '',
                    cicilan_tanggal: '',
                    cicilan_jumlah: '',
                    gelombang_id: '',
                    formulir_id: '',
                    informasi_reg_ulang: '',
                    url: '',
                    list_cicilan: [],
                    lapor_file: '',
                    lapor_file_prev: '',
                    cicilan_file: '',
                    cicilan_file_prev: ''
                },
                dtStepDataberkas: [],
                form: {
                    message_reject: '',
                    statusRejected: ''
                },
                sendbyEmail: true,
                statusSendbyEmail: '',
                status: {
                    statusPendaftaran: '',
                    statuSstep: '',
                },
                uploadPercentage: 0,
                uploadPercentageCicilan: 0,
            }
        },
        validations: {
            form: {
                deskripsiCicilan: {
                    required
                },
                totalCicilan: {
                    required
                },
                tanggalCicilan: {
                    required
                },
                jumlahCicilan: {
                    required
                },
            }
        },
        created() {
            this.getData();
        },
        methods: {
            hide_modal() {
                this.$modal.hide('my-modal-daftar-ulang')
            },
            previewFiles(e) {
                
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_image = URL.createObjectURL(file);
                    const preview_area = document.getElementById("file-uploaded-section");
                    const prebview_before = document.getElementById("file-uploaded-section-before");
                    const preview_area_image = document.getElementById("file-uploaded-area");

                    this.detailregister.lapor_file = e.target.files[0].name;

                    preview_area.classList.remove("d-none");
                    prebview_before.classList.add("d-none");
                    preview_area_image.style.backgroundImage = "url('"+this.preview_image+"')";

                    this.file_attachment = file;
                }
            },
            previewFilesCicilan(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_image = URL.createObjectURL(file);
                    const preview_area = document.getElementById("file-uploaded-section-cicilan");
                    const prebview_before = document.getElementById("file-uploaded-section-before-cicilan");
                    const preview_area_image = document.getElementById("file-uploaded-area-cicilan");
                    const upload_button = document.getElementById("upload-button-cicilan");

                    this.detailregister.cicilan_file = e.target.files[0].name;

                    preview_area.classList.remove("d-none");
                    prebview_before.classList.add("d-none");
                    upload_button.classList.remove("d-none");
                    preview_area_image.style.backgroundImage = "url('"+this.preview_image+"')";

                    this.file_cicilan = file;
                }
            },
            uploadFileAttachment : function() {
                const upload_bar = document.getElementById("upload-bar");
                const progress_button = document.getElementById("progress-button");
                const submit_button = document.getElementById("submit-button");

                upload_bar.classList.remove("d-none");
                progress_button.classList.remove("d-none");
                submit_button.classList.add("d-none");

                // const url_api   = 'https://victoria-worker.edmission.id/';
                const callback  = this.$apiconfig + 'website/registrasi/save_berkas_lapor_diri';
                let formData    = new FormData();


                if(this.file_attachment != ''){
                    formData.append('file[]', this.file_attachment);
                    formData.append('file_path', "registrasi/lapordiri/"+this.$route.params.id);
                    formData.append('callback', callback);

                    this.$http.post(this.$api_upload_engine, formData , {
                        onUploadProgress: function(progressEvent) {
                            this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        }.bind(this)
                    })
                    .then(response => {
                        const res       = response.data;
                        if (res.status && res.result != "") {
                            this.$http.post(this.$apiconfig + 'website/registrasi/save_berkas_lapor_diri', {
                                registrasi_id   : this.$route.params.id,
                                file_attachment : res.result[0]['path'],
                                laporDeskripsi  : this.detailregister.lapor_deskripsi
                            })
                            .then(response => {
                                if (response.data.status) {
                                    upload_bar.classList.add("d-none");
                                    progress_button.classList.add("d-none");
                                    submit_button.classList.remove("d-none");

                                    this.detailregister.lapor_file = response.data.file_manager.lapor_file;
                                    this.detailregister.lapor_file_prev = response.data.file_manager.lapor_file;

                                    this.$swal({
                                        icon: 'success',
                                        title: 'Berhasil',
                                        text: response.data.message,
                                        confirmButtonText: 'OK',
                                        showCancelButton: false,
                                    })
                                } else {
                                    upload_bar.classList.add("d-none");
                                    progress_button.classList.add("d-none");
                                    submit_button.classList.remove("d-none");

                                    this.$swal({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: response.data.message,
                                    })
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Gagal mengunggah file, coba lagi nanti!',
                            })

                            upload_bar.classList.add("d-none");
                            progress_button.classList.add("d-none");
                            submit_button.classList.remove("d-none");
                        }
                    });
                }else{
                    formData.append('registrasi_id', this.$route.params.id);
                    formData.append('file_attachment', "");
                    formData.append('laporDeskripsi', this.detailregister.lapor_deskripsi);

                    this.$http.post(this.$apiconfig + 'website/registrasi/save_berkas_lapor_diri', formData , {
                        onUploadProgress: function(progressEvent) {
                            this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        }.bind(this)
                    })
                    .then(response => {
                        if (response.data.status) {
                            upload_bar.classList.add("d-none");
                            progress_button.classList.add("d-none");
                            submit_button.classList.remove("d-none");

                            this.$swal({
                                icon: 'success',
                                title: 'Berhasil',
                                text: response.data.message,
                                confirmButtonText: 'OK',
                                showCancelButton: false,
                            })
                        } else {
                            upload_bar.classList.add("d-none");
                            progress_button.classList.add("d-none");
                            submit_button.classList.remove("d-none");

                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            })
                        }
                    });
                }
            },
            uploadFileCicilan : function() {
                const upload_bar = document.getElementById("upload-bar-cicilan");
                const progress_button = document.getElementById("progress-button-cicilan");
                const upload_button = document.getElementById("upload-button-cicilan");

                upload_button.classList.add("d-none");
                upload_bar.classList.remove("d-none");
                progress_button.classList.remove("d-none");

                // const url_api   = 'https://victoria-worker.edmission.id/';
                const callback  = this.$apiconfig + 'website/registrasi/save_berkas_lapor_diri_cicilan';
                let formData    = new FormData();

                formData.append('file[]', this.file_cicilan);
                formData.append('file_path', "registrasi/lapordiri/"+this.$route.params.id);
                formData.append('callback', callback);

                this.$http.post(this.$api_upload_engine, formData , {
                    onUploadProgress: function(progressEvent) {
                        this.uploadPercentageCicilan = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                    }.bind(this)
                })
                .then(response => {
                    const res       = response.data;
                    if (res.status && res.result != "") {
                        this.$http.post(this.$apiconfig + 'website/registrasi/save_berkas_lapor_diri_cicilan', {
                            registrasi_id   : this.$route.params.id,
                            file_attachment : res.result[0]['path']
                        })
                        .then(response => {
                            if (response.data.status) {
                                upload_bar.classList.add("d-none");
                                progress_button.classList.add("d-none");
                                upload_button.classList.add("d-none");

                                this.detailregister.cicilan_file = response.data.file_manager.cicilan_file;
                                this.detailregister.cicilan_file_prev = response.data.file_manager.cicilan_file;

                                this.$swal({
                                    icon: 'success',
                                    title: 'Berhasil',
                                    text: response.data.message,
                                    confirmButtonText: 'OK',
                                    showCancelButton: false,
                                })
                            } else {
                                upload_bar.classList.add("d-none");
                                progress_button.classList.add("d-none");
                                upload_button.classList.remove("d-none");

                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: response.data.message,
                                })
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Gagal mengunggah file, coba lagi nanti!',
                        })

                        upload_bar.classList.add("d-none");
                        progress_button.classList.add("d-none");
                        upload_button.classList.remove("d-none");
                    }
                });
            },
            getData() {
                this.statusSendbyEmail = "Iya";
                this.fetching = true;
                // GET STEP HISTORY & POSSION AKTIF
                this.$http.get(this.baseUrl + `admin/registrasi/data/positionstep?id=${this.$route.params.id}`)
                    .then((response) => {
                        this.detaiPositon.step_registrasi_id = response.data.data[0].step_registrasi_id;
                        this.detaiPositon.status = response.data.data[0].status;
                        if(response.data.data[0].url == 'cicilan'){
                            this.detaiPositon.url = 'tinjauan';
                            this.currentTabComponent = 'tinjauan';
                        }else{
                            this.detaiPositon.url = response.data.data[0].url;
                            this.currentTabComponent = response.data.data[0].url;
                        }
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                // GET DATA REGISTER
                this.$http.get(this.baseUrl + `admin/registrasi/data/detail?id=${this.$route.params.id}`)
                    .then((result) => {
                        this.detailregister.id = result.data.data[0].id; // tb Registrasi
                        this.detailregister.users_id = result.data.data[0].users_id; // tb Registrasi
                        this.detailregister.kode_registrasi = result.data.data[0].kode_registrasi; // tb Registrasi
                        this.detailregister.setup_formulir_id = result.data.data[0].setup_formulir_id; // tb Registrasi
                        this.detailregister.gelombang_id = result.data.data[0].gelombang_id; // tb Setup Formulir
                        this.detailregister.formulir_id = result.data.data[0].formulir_id; // tb Setup Formulir
                        this.detailregister.url_tes = result.data.data[0].url_tes; // tb Setup Formulir
                        this.detailregister.jadwal_tes = result.data.data[0].jadwal_tes; // tb Setup Formulir
                        this.detailregister.payment_gateway_information = result.data.data[0].payment_gateway_information; // tb Setup Formulir
                        this.detailregister.external_gateway_information = result.data.data[0].external_gateway_information; // tb Setup Formulir
                        this.detailregister.informasi_tes = result.data.data[0].informasi_tes; // tb Setup Formulir
                        this.detailregister.informasi_reg_ulang = result.data.data[0].informasi_reg_ulang; // tb Setup Formulir
                        this.detailregister.deskripsi_formulir = result.data.data[0].deskripsi_formulir; // tb Setup Formulir
                        this.detailregister.deskripsi_lulus = result.data.data[0].deskripsi_lulus; // tb Setup Formulir
                        this.detailregister.deskripsi_tidak_lulus = result.data.data[0].deskripsi_tidak_lulus; // tb Setup Formulir
                        this.detailregister.kode_formulir = result.data.data[0].kode_formulir; // tb Master Formulir
                        this.detailregister.nama_formulir = result.data.data[0].nama_formulir; // tb Master Formulir
                        this.detailregister.unit = result.data.data[0].unit; // tb Master Formulir
                        this.detailregister.keterangan = result.data.data[0].keterangan; // tb Master Formulir
                        this.detailregister.fullname = result.data.data[0].fullname; // tb Users
                        this.detailregister.email = result.data.data[0].email; // tb Users
                        this.detailregister.phone = result.data.data[0].phone; // tb Users
                        this.detailregister.status_cicilan = result.data.data[0].status_cicilan;
                        this.detailregister.cicilan_deskripsi = result.data.data[0].cicilan_deskripsi;
                        this.detailregister.cicilan_total = result.data.data[0].cicilan_total;
                        this.detailregister.cicilan_total_show = result.data.data[0].cicilan_total;
                        this.detailregister.cicilan_tanggal = result.data.data[0].cicilan_tanggal;
                        this.detailregister.cicilan_jumlah = result.data.data[0].cicilan_jumlah;
                        this.detailregister.cicilan_file = result.data.data[0].cicilan_file;
                        this.detailregister.cicilan_file_prev = result.data.data[0].cicilan_file;
                        this.detailregister.lapor_deskripsi = result.data.data[0].lapor_deskripsi;
                        this.detailregister.lapor_file = result.data.data[0].lapor_file;
                        this.detailregister.lapor_file_prev = result.data.data[0].lapor_file;
                        this.detailregister.list_cicilan = result.data.data[0].list_cicilan;
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                /// GET DATA FORMULIR DATA BERKAS
                this.$http.get(this.$apiconfig + `admin/registrasi/data/databerkas/daftar-ulang?registrasi_id=${this.$route.params.id}`)
                    .then((response) => {
                        this.dtStepDataberkas = response.data.data;
                        this.status.statusPendaftaran = response.data.statusPendaftaran;
                        this.status.statuSstep = response.data.statuSstep;
                        if (response.data.status == false) {
                            this.dtStepDataberkas = [];
                        }
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            // STEP DAFTAR ULANG
            reject_daftar_ulang() {
                this.$modal.show('my-modal-daftar-ulang');
            },
            validasi_daftar_ulang() {
                const upload_bar = document.getElementById("upload-bar");
                const progress_button = document.getElementById("progress-button-submit");
                const submit_button = document.getElementById("submit-save");

                upload_bar.classList.remove("d-none");
                progress_button.classList.remove("d-none");
                submit_button.classList.add("d-none");

                // const url_api   = 'https://victoria-worker.edmission.id/';
                const callback  = this.baseUrl + 'admin/registrasi/validasi/daftar-ulang';
                let formData    = new FormData();

                if(this.file_attachment != ''){
                    formData.append('file[]', this.file_attachment);
                    formData.append('file_path', "registrasi/lapordiri/"+this.$route.params.id);
                    formData.append('callback', callback);

                    this.$http.post(this.$api_upload_engine, formData , {
                        onUploadProgress: function(progressEvent) {
                            this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        }.bind(this)
                    })
                    .then(response => {
                        const res       = response.data;

                        if (res.status && res.result != "") {
                            let fd    = new FormData();
                            fd.append('file_attachment', res.result[0]['path']);
                            fd.append('registrasi_id', this.detailregister.id);
                            fd.append('message_reject', this.form.message_reject);
                            fd.append('statusRejected', this.form.statusRejected);
                            fd.append('statusSendbyEmail', this.detailregister.statusSendbyEmail);
                            fd.append('statusCicilan', this.detailregister.status_cicilan);
                            fd.append('deskripsiCicilan', this.detailregister.cicilan_deskripsi);
                            fd.append('statusSendbyEmail', this.statusSendbyEmail);
                            fd.append('totalCicilan', this.detailregister.cicilan_total);
                            fd.append('tanggalCicilan', this.convertDate(this.detailregister.cicilan_tanggal));
                            fd.append('jumlahCicilan', this.detailregister.cicilan_jumlah);
                            fd.append('laporDeskripsi', this.detailregister.lapor_deskripsi);
                            if(this.detailregister.cicilan_jumlah > 0 && this.detailregister.status_cicilan == 1){
                                for (let i = 0; i < this.detailregister.cicilan_jumlah; i++) {
                                    fd.append('listCicilan[]', this.detailregister.list_cicilan[i].cicilan);
                                }
                            }

                            this.$http({
                                url: this.baseUrl + 'admin/registrasi/validasi/daftar-ulang',
                                method: 'post',
                                data: fd
                            }).then((response) => {
                                if (response.data.status) {
                                    upload_bar.classList.add("d-none");
                                    progress_button.classList.add("d-none");
                                    submit_button.classList.remove("d-none");

                                    this.$swal({
                                        icon: 'success',
                                        title: 'Berhasil',
                                        text: response.data.message,
                                        confirmButtonText: 'OK',
                                        showCancelButton: false,
                                    })
                                    location.reload();
                                } else {
                                    upload_bar.classList.add("d-none");
                                    progress_button.classList.add("d-none");
                                    submit_button.classList.remove("d-none");

                                    this.$swal({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: response.data.message,
                                    })
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Gagal mengunggah file, coba lagi nanti!',
                            })

                            upload_bar.classList.add("d-none");
                            progress_button.classList.add("d-none");
                            submit_button.classList.remove("d-none");
                        }
                        this.$modal.hide('my-modal-daftar-ulang')
                        this.detaiPositon.status = 'applied';
                        this.form.message_reject = '';
                        this.form.statusRejected = '';
                        this.getData();
                    });
                }else{
                    formData.append('file_attachment', "");
                    formData.append('registrasi_id', this.detailregister.id);
                    formData.append('message_reject', this.form.message_reject);
                    formData.append('statusRejected', this.form.statusRejected);
                    formData.append('statusSendbyEmail', this.detailregister.statusSendbyEmail);
                    formData.append('statusCicilan', this.detailregister.status_cicilan);
                    formData.append('deskripsiCicilan', this.detailregister.cicilan_deskripsi);
                    formData.append('statusSendbyEmail', this.statusSendbyEmail);
                    formData.append('totalCicilan', this.detailregister.cicilan_total);
                    formData.append('tanggalCicilan', this.convertDate(this.detailregister.cicilan_tanggal));
                    formData.append('jumlahCicilan', this.detailregister.cicilan_jumlah);
                    formData.append('laporDeskripsi', this.detailregister.lapor_deskripsi);
                    if(this.detailregister.cicilan_jumlah > 0 && this.detailregister.status_cicilan == 1){
                        for (let i = 0; i < this.detailregister.cicilan_jumlah; i++) {
                            formData.append('listCicilan[]', this.detailregister.list_cicilan[i].cicilan);
                        }
                    }

                    this.$http.post(this.baseUrl + 'admin/registrasi/validasi/daftar-ulang', formData , {
                        onUploadProgress: function(progressEvent) {
                            this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        }.bind(this)
                    })
                    .then(response => {
                        if (response.data.status) {
                            upload_bar.classList.add("d-none");
                            progress_button.classList.add("d-none");
                            submit_button.classList.remove("d-none");

                            this.$swal({
                                icon: 'success',
                                title: 'Berhasil',
                                text: response.data.message,
                                confirmButtonText: 'OK',
                                showCancelButton: false,
                            })
                            location.reload();
                        } else {
                            upload_bar.classList.add("d-none");
                            progress_button.classList.add("d-none");
                            submit_button.classList.remove("d-none");

                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            })
                        }
                        this.$modal.hide('my-modal-daftar-ulang')
                        this.detaiPositon.status = 'applied';
                        this.form.message_reject = '';
                        this.form.statusRejected = '';
                        this.getData();
                    });
                }

            },
            validasi_daftar_ulang_reject() {
                if (this.form.statusRejected == '') {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Status reject boleh kosong',
                    })
                } else {
                    var fd = new FormData()
                    fd.append('registrasi_id', this.detailregister.id);
                    fd.append('message_reject', this.form.message_reject);
                    fd.append('statusRejected', this.form.statusRejected);
                    fd.append('statusSendbyEmail', this.statusSendbyEmail);
                    fd.append('deskripsiCicilan', this.detailregister.cicilan_deskripsi);
                    fd.append('totalCicilan', this.detailregister.cicilan_total);
                    fd.append('tanggalCicilan', this.convertDate(this.detailregister.cicilan_tanggal));
                    fd.append('statusCicilan', this.detailregister.status_cicilan);
                    fd.append('jumlahCicilan', this.detailregister.cicilan_jumlah);
                    if(this.detailregister.cicilan_jumlah > 0){
                        for (let i = 0; i < this.detailregister.cicilan_jumlah; i++) {
                            fd.append('listCicilan[]', this.detailregister.list_cicilan[i].cicilan);
                        }
                    }
                    this.$http({
                        url: this.baseUrl + 'admin/registrasi/validasi/daftar-ulang',
                        method: 'post',
                        data: fd
                    }).then((response) => {
                        if (response.data.status == true) {
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        } else {
                            this.$swal({
                                icon: 'info',
                                title: "info",
                                text: response.data.message,
                                showConfirmButton: true,
                            })
                        }
                        this.$modal.hide('my-modal-daftar-ulang')
                        this.detaiPositon.status = 'rejected';
                        this.form.message_reject = '';
                        this.form.statusRejected = '';
                        this.getData();
                    }).catch(error => {
                        console.log(error.response.data)
                    })
                }
            },
            generate_cicilan() {
                // this.list_cicilan = [];
                // for (let i = 0; i < this.detailregister.cicilan_jumlah; i++) {
                //     this.list_cicilan.push('0');
                // }

                var fd = new FormData()
                fd.append('deskripsiCicilan', this.detailregister.cicilan_deskripsi);
                fd.append('totalCicilan', this.detailregister.cicilan_total);
                fd.append('registrasi_id', this.detailregister.id);
                fd.append('tanggalCicilan', this.convertDate(this.detailregister.cicilan_tanggal));
                fd.append('jumlahCicilan', this.detailregister.cicilan_jumlah);
                this.$http({
                    url: this.baseUrl + 'admin/registrasi/validasi/generate-cicilan',
                    method: 'post',
                    data: fd
                }).then((response) => {
                    if (response.data.status == true) {
                        this.getData();
                    } else {
                        this.getData();
                    }
                }).catch(error => {
                    console.log(error.response.data)
                })
                
            },
            // SEND EMAIL
            sendmail() {
                if (this.sendbyEmail == true) {
                    this.statusSendbyEmail = "Iya";
                } else {
                    this.statusSendbyEmail = "Tidak";
                }
            },
            download(url, filename) {
                fetch(url)
                    .then(response => response.blob())
                    .then(blob => {
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = filename;
                        link.click();
                    })
                    .catch(console.error);
            },
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
            },
            convertMount : function (date) {
                return moment(date, 'YYYY-MM-DD').format('DD MMMM YYYY'); 
            },
        },
    }
</script>