<template>
    <div>
        <!-- MODALS Keterangan Penolakan ================================================== -->
        <modal name="my-modal-pendaftaran" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h4 class="card-header-title">
                            Keterangan Penolakan
                        </h4>
                        <!-- Close -->
                        <button type="button" class="close" @click="hide_modal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="card-body">
                            <div class="form-group">
                                <label>Status<span class="text-danger">*</span></label>
                                <select class="form-control" v-model="form.statusRejected" required>
                                    <option value="" selected>Pilih</option>
                                    <option value="rejected">Rejected</option>
                                    <option value="re-check">Re-Check</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Keterangan</label>
                                <textarea v-model="form.message_reject" class="form-control" cols="30" rows="6" required></textarea>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal & Tutup</button>
                                <button @click.prevent="validasi_pendaftaran_reject()" type="button" class="btn btn-primary mr-2">Simpan</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <!-- CONTENT -->
        <div class="d-block">
            <div v-if="dtStepPendaftaran.length == 0">
                <div class="p-4">
                    <div v-if="status.statuSstep == 'pending'" class="alert alert-light shadow">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 mr-4">
                                <span class="fe fe-alert-triangle h2"></span>
                            </div>
                            <div>{{ status.statusPendaftaran }}.</div>
                        </div>
                    </div>
                    <div v-else class="alert alert-light shadow m-0">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 mr-4">
                                <span class="fe fe-alert-triangle h2 m-0"></span>
                            </div>
                            <div>Calon peserta belum menyelesaikan semua registrasi sebelumya.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="detaiPositon.status == 'pending' && detaiPositon.url == 'pendaftaran'">
                    <div class="p-4">
                        <div class="alert alert-light shadow m-0">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 mr-4">
                                    <span class="fe fe-alert-triangle h2 m-0"></span>
                                </div>
                                <div>{{ status.statusPendaftaran }}.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row mb-2">
                    <div class="col-md-6">
                        <div class="card-body pb-0">
                            <div class="d-block mb-4" v-for="(dtPendaftaran, index) in dtStepPendaftaran" :key="index">
                                <div class="d-flex">
                                    <div class="icon-circle flex-shrink-0 mr-3 text-muted">1</div>
                                    <div class="flex-grow-1">
                                        <button class="btn btn-link btn-block text-left px-0 text-dark font-weight-bold pb-0 pt-md-1 pt-3 h3 m-0 btn-collapse" type="button" data-toggle="collapse" data-target="#asalPendaftar" aria-expanded="false">
                                            <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
                                                <div class="font-weight-bold">
                                                    Asal Pendaftar
                                                </div>
                                                <div class="text-right"><span class="fe fe-chevron-down"></span></div>
                                            </div>
                                        </button>

                                        <div class="collapse pt-3" id="asalPendaftar">
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataAsalPendaftar.default.kewarganegaraan.value == '1'">
                                                <div class="mr-3">Kewarganegaraan</div>
                                                <div class="d-block font-weight-bold">
                                                    <span v-if="dtPendaftaran.kewarganegaraan == 'wni'">WNI</span>
                                                    <span v-if="dtPendaftaran.kewarganegaraan == 'wna'">WNA</span>
                                                </div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataAsalPendaftar.default.asal_provinsi.value == '1'">
                                                <div class="mr-3">Provinsi Asal Calon Peserta </div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.asal_provinsi_nama}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataAsalPendaftar.default.asal_kota.value == '1'">
                                                <div class="mr-3">Kota Asal Calon Peserta </div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.asal_kota_nama}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataAsalPendaftar.default.asal_pilihan_sekolah.value == '1'">
                                                <div class="mr-3">Asal Sekolah</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.asal_pilihan_sekolah}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataAsalPendaftar.default.asal_sekolah.value == '1'">
                                                <div class="mr-3">Nama Asal Sekolah</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.asal_sekolah}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataAsalPendaftar.default.npsn.value == '1'">
                                                <div class="mr-3">NPSN (Nomor Pokok Sekolah Nasional) *Khusus untuk masuk SD, SMP, SMA</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.npsn}}</div>
                                            </div>

                                            <div class="d-block pb-2 mb-2 border-bottom" v-for="(item, index) in dataAsalPendaftar.customList" :key="index">
                                                <div class="mr-3">{{item.name}}</div>
                                                <div class="d-block font-weight-bold" v-if="customAsalPendaftar[item.code]">
                                                    {{customAsalPendaftar[item.code]}}
                                                </div>

                                                <div class="d-block font-weight-bold" v-if="!customAsalPendaftar[item.code]">
                                                    -
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="d-block mb-4" v-for="(dtPendaftaran, index) in dtStepPendaftaran" :key="index">
                                <div class="d-flex">
                                    <div class="icon-circle flex-shrink-0 mr-3 text-muted">2</div>
                                    <div class="flex-grow-1">
                                        <button class="btn btn-link btn-block text-left px-0 text-dark font-weight-bold pb-0 pt-md-1 pt-3 h3 m-0 btn-collapse" type="button" data-toggle="collapse" data-target="#dataPendaftar" aria-expanded="false">
                                            <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
                                                <div class="font-weight-bold">
                                                    Data Pendaftar
                                                </div>
                                                <div class="text-right"><span class="fe fe-chevron-down"></span></div>
                                            </div>
                                        </button>
                                        <div class="collapse pt-3" id="dataPendaftar">
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.nama_lengkap.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">Nama Calon Peserta</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.nama_lengkap}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.jenis_kelamin.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">Jenis Kelamin</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.jenis_kelamin == "L" ? 'Laki - laki':'Perempuan'}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.tempat_lahir.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">Tempat Lahir</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.tempat_lahir}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.tanggal_lahir.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">Tanggal Lahir</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.tanggal_lahir}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.nisn.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">NISN</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.nisn}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.nik.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">NIK</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.nik}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.nomor_handphone.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">No. handphone</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.nomor_handphone}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.email.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">Email</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.email}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.anak_ke.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">Anak Ke</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.anak_ke}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.jumlah_saudara.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">Dari</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.jumlah_saudara}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.provinsi.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">Provinsi</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.provinsi_nama}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.kota.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">Kota</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.kota_nama}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.kecamatan.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">Kecamatan</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.kecamatan_nama}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.kelurahan.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">Kelurahan</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.kelurahan_nama}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.rt.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">RT</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.rt}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.rw.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">RW</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.rw}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-if="dataPendaftar.default.alamat_lengkap.value == '1'">
                                                <div class="mr-md-4 mr-3 flex-shrink-0">Alamat Lengkap</div>
                                                <div class="d-block font-weight-bold">{{dtPendaftaran.alamat_lengkap}}</div>
                                            </div>
                                            <div class="d-block pb-2 mb-2 border-bottom" v-for="(item, index) in dataPendaftar.customList" :key="index">
                                                <div class="mr-3">{{item.name}}</div>
                                                <div class="d-block font-weight-bold" v-if="customDataPendaftar[item.code]">
                                                    {{customDataPendaftar[item.code]}}
                                                </div>

                                                <div class="d-block font-weight-bold" v-if="!customDataPendaftar[item.code]">
                                                    -
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="dtStepDatawali_flag == 'ortu'">
                                <div class="d-block mb-4" v-for="(dtWali, index) in dtStepDatawali" :key="index">
                                    <div class="d-flex">
                                        <div class="icon-circle flex-shrink-0 mr-3 text-muted">3</div>
                                        <div class="flex-grow-1">
                                            <button class="btn btn-link btn-block text-left px-0 text-dark font-weight-bold pb-0 pt-md-1 pt-3 h3 m-0 btn-collapse" type="button" data-toggle="collapse" data-target="#dataOrangTua" aria-expanded="false">
                                                <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
                                                    <div class="font-weight-bold">
                                                        Data Orang Tua
                                                    </div>
                                                    <div class="text-right"><span class="fe fe-chevron-down"></span></div>
                                                </div>
                                            </button>
                                            <div class="collapse pt-3" id="dataOrangTua">
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.nama_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Nama ayah</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.nama_ayah}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.alamat_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Alamat Rumah Tinggal ayah</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.alamat_ayah}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.telepon_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Telepon Rumah Tinggal ayah</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.telepon_ayah}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.pekerjaan_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Pekerjaan ayah</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.pekerjaan_ayah}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.kantor_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Nama Kantor/Perusahaan ayah</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.kantor_ayah}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.alamat_kantor_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Alamat Kantor/Perusahaan ayah</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.alamat_kantor_ayah}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.email_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Email ayah</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.email_ayah}}</div>
                                                </div>

                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.nama_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Nama ibu</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.nama_ibu}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.alamat_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Alamat Rumah Tinggal ibu</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.alamat_ibu}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.telepon_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Telepon Rumah Tinggal ibu</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.telepon_ibu}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.pekerjaan_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Pekerjaan ibu</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.pekerjaan_ibu}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.kantor_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Nama Kantor/Perusahaan ibu</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.kantor_ibu}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.alamat_kantor_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Alamat Kantor/Perusahaan ibu</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.alamat_kantor_ibu}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.email_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Email ibu</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.email_ibu}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-for="(item, index) in dataWali.customList" :key="index">
                                                    <div class="mr-3">{{item.name}}</div>
                                                    <div class="d-block font-weight-bold" v-if="customDataWali[item.code]">
                                                        {{customDataWali[item.code]}}
                                                    </div>

                                                    <div class="d-block font-weight-bold" v-if="!customDataWali[item.code]">
                                                        -
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="d-block mb-4" v-for="(dtWali, index) in dtStepDatawali" :key="index">
                                    <div class="d-flex">
                                        <div class="icon-circle flex-shrink-0 mr-3 text-muted">3</div>
                                        <div class="flex-grow-1">
                                            <button class="btn btn-link btn-block text-left px-0 text-dark font-weight-bold pb-0 pt-md-1 pt-3 h3 m-0 btn-collapse" type="button" data-toggle="collapse" data-target="#dataWali" aria-expanded="false">
                                                <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
                                                    <div class="font-weight-bold">
                                                        Data Wali
                                                    </div>
                                                    <div class="text-right"><span class="fe fe-chevron-down"></span></div>
                                                </div>
                                            </button>
                                            <div class="collapse pt-3" id="dataWali">
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.nama_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Nama wali</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.nama_wali}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.alamat_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Alamat Rumah Tinggal wali</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.alamat_wali}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.telepon_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Telepon Rumah Tinggal wali</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.telepon_wali}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.pekerjaan_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Pekerjaan wali</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.pekerjaan_wali}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.kantor_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Nama Kantor/Perusahaan wali</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.kantor_wali}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.alamat_kantor_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Alamat Kantor/Perusahaan wali</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.alamat_kantor_wali}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-if="dataWali.default.email_wali.value == '1'">
                                                    <div class="mr-3 flex-shrink-0">Email wali</div>
                                                    <div class="d-block font-weight-bold">{{dtWali.email_wali}}</div>
                                                </div>
                                                <div class="d-block pb-2 mb-2 border-bottom" v-for="(item, index) in dataWali.customList" :key="index">
                                                    <div class="mr-3">{{item.name}}</div>
                                                    <div class="d-block font-weight-bold" v-if="customDataWali[item.code]">
                                                        {{customDataWali[item.code]}}
                                                    </div>

                                                    <div class="d-block font-weight-bold" v-if="!customDataWali[item.code]">
                                                        -
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card-body pb-0">
                            <div class="d-block mb-4">
                                <div class="d-flex">
                                    <div class="icon-circle flex-shrink-0 mr-3 text-muted">4</div>
                                    <div class="flex-grow-1">
                                        <button class="btn btn-link btn-block text-left px-0 text-dark font-weight-bold pb-0 pt-md-1 pt-3 h3 m-0 btn-collapse" type="button" data-toggle="collapse" data-target="#dataSaudara" aria-expanded="false">
                                            <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
                                                <div class="font-weight-bold">
                                                    Kakak/Adik yang bersekolah disini
                                                </div>
                                                <div class="text-right"><span class="fe fe-chevron-down"></span></div>
                                            </div>
                                        </button>
                                        <div class="collapse pt-3" id="dataSaudara">
                                            <!-- <div class="d-block">
                                                <div v-for="(saudara, index) in keterangan_wali_lainnya" :key="index" class="d-block pb-2 mb-2 border-bottom">
                                                    <div>{{ saudara.name }}</div>
                                                    <div class="d-block font-weight-bold">{{ saudara.school }}</div>
                                                </div>
                                            </div> -->

                                            <div v-if="wali_lainnya == 'iya'">
                                                <div v-for="(saudara, index) in keterangan_wali_lainnya" :key="index" class="d-block">
                                                    <div class="card mb-3">
                                                        <div class="p-3">
                                                            <div class="d-block pb-2 mb-2 border-bottom">Nama Lengkap Peserta Kakak/Adik
                                                                <div class="d-block font-weight-bold">{{ saudara.name }}</div>
                                                            </div>
                                                            <div class="d-block">Nama Sekolah
                                                                <div class="d-block font-weight-bold">{{ saudara.school }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-else>
                                                <div class="text-center">
                                                    <div class="alert alert-light text-muted m-0">
                                                        <p class="m-0">Tidak ada Kakak/Adik yang bersekolah disini</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="d-block mb-4">
                                <div class="d-flex">
                                    <div class="icon-circle flex-shrink-0 mr-3 text-muted">5</div>
                                    <div class="flex-grow-1">
                                        <div class="h3 d-block mb-4 font-weight-bold pt-2">Photo</div>
                                        <div class="card mb-2 shadow-sm" v-for="(photo_pendaftar, index) in dtStepPendaftaran" :key="index">
                                            <div class="card-body py-3" if>
                                                <div class="text-center" v-if="photo_pendaftar.foto">
                                                    <div>
                                                        <img :src="photo_pendaftar.foto" style="width:200px">
                                                    </div>
                                                </div>
                                                <div class="text-center" v-else>
                                                    <div class="mt-3">
                                                        <p>Tidak ada photo pendaftar</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="d-block mb-4">
                                <div class="d-flex">
                                    <div class="icon-circle flex-shrink-0 mr-3 text-muted">5</div>
                                    <div class="flex-grow-1">
                                        <button class="btn btn-link btn-block text-left px-0 text-dark font-weight-bold pb-0 pt-md-1 pt-3 h3 m-0 btn-collapse" type="button" data-toggle="collapse" data-target="#dataBerkas" aria-expanded="false">
                                            <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
                                                <div class="font-weight-bold">
                                                    List Berkas Pendaftaran
                                                </div>
                                                <div class="text-right"><span class="fe fe-chevron-down"></span></div>
                                            </div>
                                        </button>
                                        <div class="collapse pt-3" id="dataBerkas">
                                            <div class="card mb-2 shadow-sm" v-for="(berkas_list, index) in dtStepDataberkas" :key="index">
                                                <div class="card-body p-3">
                                                    <div class="row align-items-center">
                                                        <div class="col materi-list materi-list--pdf">
                                                            <div class="d-flex align-items-center">
                                                                <div class="mb-0">
                                                                    <a class="font-weight-bold">
                                                                        {{ berkas_list.label_name }}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center my-3">
                                                        <small class="d-block  mr-3"><span class="fe fe-calendar mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Tanggal mengumpulkan"></span>{{ berkas_list.created_at }}</small>
                                                        <span class="badge badge-light mr-3"></span>
                                                    </div>
                                                    <div class="d-block">
                                                        <a data-toggle="modal" v-bind:data-target="'#cekBuktiberkaslist'+berkas_list.id" class="btn btn-sm btn-white btn-block">
                                                            <span class="fe fe-eye mr-2"></span>lihat
                                                        </a>
                                                        <div class="modal fade" height="auto" v-bind:id="'cekBuktiberkaslist'+berkas_list.id" tabindex="-1" role="dialog" aria-hidden="true">
                                                            <div class="modal-dialog modal-dialog-centered" role="document">
                                                                <div class="modal-content">
                                                                    <div class="modal-card card">
                                                                        <div class="card-header">
                                                                            <h4 class="card-header-title" id="exampleModalCenterTitle">
                                                                                Berkas
                                                                            </h4>
                                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                                <span aria-hidden="true">&times;</span>
                                                                            </button>

                                                                        </div>
                                                                        <div class="card-body" style="height: 1000px;">
                                                                            <div v-if="berkas_list.file_attachment.split('.').pop() == 'pdf'" style="height: 100%">
                                                                                <embed v-bind:src="berkas_list.file_attachment" alt="..." class="avatar-img rounded">
                                                                            </div>
                                                                            <div v-else>
                                                                                <img v-bind:src="berkas_list.file_attachment" alt="..." class="avatar-img rounded">
                                                                            </div>
                                                                        </div>
                                                                        <div class="card-footer border-top-0">
                                                                            <ul class="pagination mb-0"></ul>
                                                                            <div class="d-flex justify-content-end">
                                                                                <a @click.prevent="download(berkas_list.file_attachment,'BUKTI-BAYAR')" class="btn btn-sm btn-white d-none d-md-inline-block"> Download </a>
                                                                                <a href="#!" class="btn btn-sm btn-white d-none d-md-inline-block" data-dismiss="modal" aria-label="Close"> Tutup</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card-body">
                            <div v-if="status.statuSstep == 'waiting'" class="alert alert-warning shadow mb-4 mt-4">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 mr-4">
                                        <span class="fe fe-alert-triangle h2"></span>
                                    </div>
                                    <div>{{ status.statusPendaftaran }}.</div>
                                </div>
                            </div>
                            <div v-if="status.statuSstep == 're-check'" class="alert alert-info shadow mb-4 mt-4">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 mr-4">
                                        <span class="fe fe-alert-triangle h2"></span>
                                    </div>
                                    <div>{{ status.statusPendaftaran }}.</div>
                                </div>
                            </div>
                            <div v-if="status.statuSstep == 'rejected'" class="alert alert-danger shadow mb-4 mt-4">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 mr-4"><span class="fe fe-x-circle h2 m-0"></span></div>
                                    <div> {{ status.statusPendaftaran }}.</div>
                                </div>
                            </div>
                            <div v-if="status.statuSstep == 'applied'" class="alert alert-success shadow mb-4 mt-4">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 mr-4"><span class="fe fe-check-circle h2 m-0"></span></div>
                                    <div> {{ status.statusPendaftaran }}.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="detaiPositon.status == 'waiting' || detaiPositon.status == 're-check'" class="">
                <div class="d-block p-4">
                    <div class="d-flex align-items-center justify-content-between d-flex-mobile" v-if="detaiPositon.url == 'pendaftaran'">
                        <div class="d-block pb-2 rounded">
                            <div class="d-flex p-3 bg-light align-items-center">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" id="switchOne" v-model="sendbyEmail" @change="sendmail()" class="custom-control-input">
                                    <label for="switchOne" class="custom-control-label"></label>
                                </div>
                                <label class="font-weight-bold my-0 ml-2">Kirim Notifikasi Email</label>
                            </div>
                        </div>
                        <div>
                            <button @click.prevent="validasi_pendaftaran()" type="button" class="btn btn-primary mr-2 px-md-4 btn-rounded">
                                <span class="fe fe-check-circle mr-2"></span>Approve
                            </button>
                            <button @click.prevent="reject_pendaftaran()" type="button" class="btn btn-danger px-md-4 btn-rounded">
                                <span class="fe fe-x-circle mr-2"></span>Reject
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div  v-if="status.statuSstep == 'waiting' || status.statuSstep == 're-check' || status.statuSstep == 'applied'" class="border-top">
                <div class="d-block p-4">
                    <div class="d-flex align-items-center justify-content-between d-flex-mobile">
                        <div class="d-block pb-2 rounded">
                            <!-- -->
                            <div class="mt-3 d-flex align-items-center">
                                <label class="mr-4 font-weight-bold my-0">Kategori: </label>
                                <div v-for="(kategori, index) in listKategori" :key="index">
                                <input v-model="detailregister.kategori" type="radio" id="kategori" name="kategori" :value="kategori.id">
                                <label class="mr-4 font-weight-bold my-0 ml-2">{{ kategori.kategori }}</label>
                                </div>
                            </div>
                        </div>
                        <div v-if="detaiPositon.url != 'pendaftaran'">
                            <button @click="saveKategori()" type="button" class="btn btn-primary mr-2 px-md-4 btn-rounded">
                                <span class="fe fe-save mr-2"></span>Simpan
                            </button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                baseUrl: this.$apiconfig,
                fetching: true,
                detaiPositon: {
                    step_registrasi_id: '',
                    status: '',
                    url: ''
                },
                detailregister: {
                    id: '',
                    kode_registrasi: '',
                    setup_formulir_id: '',
                    users_id: '',
                    fullname: '',
                    email: '',
                    phone: '',
                    status_pendaftaran: '',
                    status_pembayaran: '',
                    status_testing: '',
                    status_daftar_ulang: '',
                    gelombang_id: '',
                    formulir_id: '',
                },
                dtStepPendaftaran: [],
                dtStepDatawali: [],
                dtStepDataberkas: [],
                dtStepDatawali_flag: '',
                wali_lainnya: '',
                keterangan_wali_lainnya: [],
                listKategori: [],
                form: {
                    message_reject: '',
                    statusRejected: '',
                },
                sendbyEmail: true,
                statusSendbyEmail: '',
                status: {
                    statusPendaftaran: '',
                    statuSstep: '',
                },
                dataAsalPendaftar   : {
                    default: {
                        kewarganegaraan : {
                            required : '',
                            value : ''
                        },
                        asal_provinsi : {
                            required : '',
                            value : ''
                        },
                        asal_kota : {
                            required : '',
                            value : ''
                        },
                        asal_pilihan_sekolah : {
                            required : '',
                            value : ''
                        },
                        asal_sekolah : {
                            required : '',
                            value : ''
                        },
                        npsn : {
                            required : '',
                            value : ''
                        }
                    },
                    customList : [],
                },
                customAsalPendaftar : {},
                dataPendaftar   : {
                    default     : [],
                    customList  : [],
                },
                customDataPendaftar: {},
                dataWali : {
                    default: {
                        nama_wali : {
                            required : '',
                            value : ''
                        },
                        alamat_wali : {
                            required : '',
                            value : ''
                        },
                        telepon_wali : {
                            required : '',
                            value : ''
                        },
                        pekerjaan_wali : {
                            required : '',
                            value : ''
                        },
                        kantor_wali : {
                            required : '',
                            value : ''
                        },
                        alamat_kantor_wali : {
                            required : '',
                            value : ''
                        },
                        telepon_kantor_wali : {
                            required : '',
                            value : ''
                        },
                        email_wali : {
                            required : '',
                            value : ''
                        },
                        wali_lainnya : {
                            required : '',
                            value : ''
                        },
                        keterangan_wali_lainnya : {
                            required : '',
                            value : ''
                        }
                    },
                    customList  : [],
                },
                customDataWali: {}
            }
        },
        created() {
            this.getData();
            this.getKategori();
        },
        methods: {
            hide_modal() {
                this.$modal.hide('my-modal-pendaftaran')
            },
            getData() {
                this.statusSendbyEmail = "Iya"
                this.fetching = true;
                // GET STEP HISTORY & POSSION AKTIF
                this.$http.get(this.baseUrl + `admin/registrasi/data/positionstep?id=${this.$route.params.id}`)
                    .then((response) => {
                        this.detaiPositon.step_registrasi_id = response.data.data[0].step_registrasi_id;
                        this.detaiPositon.status = response.data.data[0].status;
                        this.detaiPositon.url = response.data.data[0].url;
                        this.currentTabComponent = response.data.data[0].url;
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                // GET DATA KATEGORI
                this.$http.get(this.baseUrl + `admin/registrasi/data/kategori`)
                    .then((response) => {
                        this.listKategori = response.data.data;
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                // GET DATA REGISTER
                this.$http.get(this.baseUrl + `admin/registrasi/data/detail?id=${this.$route.params.id}`)
                    .then((response) => {
                        this.detailregister.id = response.data.data[0].id; // tb Registrasi
                        this.detailregister.users_id = response.data.data[0].users_id; // tb Registrasi
                        this.detailregister.kode_registrasi = response.data.data[0].kode_registrasi; // tb Registrasi
                        this.detailregister.setup_formulir_id = response.data.data[0].setup_formulir_id; // tb Registrasi
                        this.detailregister.gelombang_id = response.data.data[0].gelombang_id; // tb Setup Formulir
                        this.detailregister.formulir_id = response.data.data[0].formulir_id; // tb Setup Formulir
                        this.detailregister.url_tes = response.data.data[0].url_tes; // tb Setup Formulir
                        this.detailregister.jadwal_tes = response.data.data[0].jadwal_tes; // tb Setup Formulir
                        this.detailregister.payment_gateway_information = response.data.data[0].payment_gateway_information; // tb Setup Formulir
                        this.detailregister.external_gateway_information = response.data.data[0].external_gateway_information; // tb Setup Formulir
                        this.detailregister.informasi_tes = response.data.data[0].informasi_tes; // tb Setup Formulir
                        this.detailregister.informasi_reg_ulang = response.data.data[0].informasi_reg_ulang; // tb Setup Formulir
                        this.detailregister.deskripsi_formulir = response.data.data[0].deskripsi_formulir; // tb Setup Formulir
                        this.detailregister.deskripsi_lulus = response.data.data[0].deskripsi_lulus; // tb Setup Formulir
                        this.detailregister.deskripsi_tidak_lulus = response.data.data[0].deskripsi_tidak_lulus; // tb Setup Formulir
                        this.detailregister.kode_formulir = response.data.data[0].kode_formulir; // tb Master Formulir
                        this.detailregister.nama_formulir = response.data.data[0].nama_formulir; // tb Master Formulir
                        this.detailregister.keterangan = response.data.data[0].keterangan; // tb Master Formulir
                        this.detailregister.fullname = response.data.data[0].fullname; // tb Users
                        this.detailregister.email = response.data.data[0].email; // tb Users
                        this.detailregister.phone = response.data.data[0].phone; // tb Users
                        this.detailregister.kategori = response.data.data[0].kategori; // tb Registrasi
                        this.detailregister.regis_kategori = response.data.data[0].regis_kategori; // tb Users
                        this.fetching = false;

                        this.getComponentAsalPendaftar();
                        this.getComponentDataPendaftar();
                        this.getComponentDataWali();
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                // GET DATA FORMULIR DATA SISWA
                this.$http.get(this.$apiconfig + `admin/registrasi/data/datasiswa?registrasi_id=${this.$route.params.id}`)
                    .then((response) => {
                        this.dtStepPendaftaran = response.data.data;

                        this.dtStepPendaftaran[0].custom_form_asal_pendaftar.forEach(element => {
                            this.customAsalPendaftar[element.code] = element.value;
                        });

                        this.dtStepPendaftaran[0].custom_form_data_pendaftar.forEach(element => {
                            this.customDataPendaftar[element.code] = element.value;
                        });

                        this.status.statusPendaftaran = response.data.statusPendaftaran;
                        this.status.statuSstep = response.data.statuSstep;
                        this.fetching = false;
                        if (response.data.status == false) {
                            this.dtStepPendaftaran = [];
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                // GET DATA FORMULIR DATA WALI
                this.$http.get(this.$apiconfig + `admin/registrasi/data/datawali?registrasi_id=${this.$route.params.id}`)
                    .then((response) => {
                        if (response.data.status == false) {
                            this.dtStepDatawali = [];
                        } else {
                            this.dtStepDatawali = response.data.data;

                            this.dtStepDatawali[0].custom_form_data_wali.forEach(element => {
                                this.customDataWali[element.code] = element.value;
                            });

                            this.dtStepDatawali_flag = response.data.flag_wali;
                            this.wali_lainnya = response.data.data[0].wali_lainnya;
                            this.keterangan_wali_lainnya = response.data.data[0].keterangan_wali_lainnya;
                            this.fetching = false;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                /// GET DATA FORMULIR DATA BERKAS
                this.$http.get(this.$apiconfig + `admin/registrasi/data/databerkas?registrasi_id=${this.$route.params.id}`)
                    .then((response) => {
                        this.dtStepDataberkas = response.data.data;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            // STEP PENDAFTARAN
            reject_pendaftaran() {
                this.$modal.show('my-modal-pendaftaran');
            },
            // VALIDASI PENDAFTARAN JIKA TIDAK SETUJUH
            validasi_pendaftaran_reject() {
                var fd = new FormData()
                if (this.form.statusRejected == '') {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Status reject boleh kosong',
                    })
                } else {
                    fd.append('registrasi_id', this.detailregister.id);
                    fd.append('message_reject', this.form.message_reject);
                    fd.append('statusRejected', this.form.statusRejected);
                    fd.append('statusSendbyEmail', this.statusSendbyEmail)
                    this.$http({
                        url: this.baseUrl + 'admin/registrasi/validasi/formulir_pendaftaran',
                        method: 'post',
                        data: fd
                    }).then((response) => {
                        if (response.data.status == true) {
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                            location.reload();
                        } else {
                            this.$swal({
                                icon: 'info',
                                title: "info",
                                text: response.data.message,
                                showConfirmButton: true,
                            })
                        }
                        this.$modal.hide('my-modal-pendaftaran')
                        this.detaiPositon.status = 'rejected';
                        this.form.message_reject = '';
                        this.form.statusRejected = '';
                        this.getData();
                    }).catch(error => {
                        console.log(error.response.data)
                    })
                }
            },
            // VALIDASI PENDAFTARAN JIKA SETUJUH
            validasi_pendaftaran() {
                var fd = new FormData()
                fd.append('registrasi_id', this.detailregister.id);
                fd.append('message_reject', '');
                fd.append('statusRejected', '');
                fd.append('statusSendbyEmail', this.statusSendbyEmail)
                fd.append('kategori', this.detailregister.kategori)
                this.$http({
                    url: this.baseUrl + 'admin/registrasi/validasi/formulir_pendaftaran',
                    method: 'post',
                    data: fd
                }).then((response) => {
                    if (response.data.status == true) {
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: response.data.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        location.reload();
                    } else {
                        this.$swal({
                            icon: 'info',
                            title: "info",
                            text: response.data.message,
                            showConfirmButton: true,
                        })
                    }
                    this.$modal.hide('my-modal-pendaftaran')
                    this.detaiPositon.status = 'applied';
                    this.form.message_reject = '';
                    this.form.statusRejected = '';
                    this.getData();
                }).catch(error => {
                    console.log(error.response.data)
                })
            },
            getKategori() {
                this.listKategori = [];
                this.$http.get(this.baseUrl + `admin/registrasi/data/kategori`)
                    .then((response) => {
                        this.listKategori = response.data.data;
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            // SEND EMAIL
            sendmail() {
                if (this.sendbyEmail == true) {
                    this.statusSendbyEmail = "Iya";
                } else {
                    this.statusSendbyEmail = "Tidak";
                }
            },
            // DOWNLOAD
            download(url, filename) {
                fetch(url)
                    .then(response => response.blob())
                    .then(blob => {
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = filename;
                        link.click();
                    })
                    .catch(console.error);
            },
            getComponentAsalPendaftar : function() {
                let uri = this.$apiconfig + 'website/registrasi/load_component_form';
                
                this.$http.get(uri, {
                    params : {
                        formulir_id : this.detailregister.setup_formulir_id,
                        flag : 'asal_pendaftar'
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataAsalPendaftar = res.data.data;
                    }
                });
            },
            getComponentDataPendaftar : function() {
                let uri = this.$apiconfig + 'website/registrasi/load_component_form';
                
                this.$http.get(uri, {
                    params : {
                        formulir_id : this.detailregister.setup_formulir_id,
                        flag : 'data_pendaftar'
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataPendaftar = res.data.data;
                    }
                });
            },
            getComponentDataWali : function() {
                let uri = this.$apiconfig + 'website/registrasi/load_component_form';
                
                this.$http.get(uri, {
                    params : {
                        formulir_id : this.detailregister.setup_formulir_id,
                        flag : 'data_wali'
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataWali = res.data.data;
                    }
                });
            },
            saveKategori(){
                let uri = this.$apiconfig + 'admin/registrasi/data/set-kategori';
                this.$http.get(uri, {
                    params : {
                        id : this.detailregister.id,
                        kategori : this.detailregister.kategori
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.detailregister.kategori = res.data.data.id;
                        this.$swal({
                            title: 'Apakah anda yakin?',
                            text: "Akan mengubah calon siswa kedalam kategori ini!",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Ya, Ubah kategori!'
                            }).then((result) => {
                            if (result.isConfirmed) {
                                this.$swal({
                                    icon: 'success',
                                    title: "success",
                                    text: 'Kategori berhasil di ubah',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                location.reload();
                            }
                        })
                    }
                });
            }
        }
    }
</script>