<template>
    <div>
        <!-- MAIN CONTENT ================================================= = -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- Breadcrumb -->
                <div class="position-fixed w-100" style="top:0;z-index:99;transform:translateX(-1px);">
                    <div class="d-flex mb-4 pt-3 pb-3 border-bottom align-items-center bg-white">
                        <a href="javascript:history.back()"><span class="fe fe-arrow-left mr-1"></span>Kembali</a>
                        <span class="text-muted mx-2">|</span>
                        <div class="text-muted">Data Registrasi</div>
                        <span class="text-muted mx-2"><span class="fe fe-arrow-right-circle mr-1 text-sm"></span></span>
                        <div class="text-muted">{{ detailregister.fullname }}</div>
                    </div>
                </div>
                <!-- -->
                <div class="d-block mb-4 pt-5 mt-3">
                    <div class="d-flex align-items-start">
                        <div class="mr-3">
                            <img src="https://scolacdn.com/frontend/argonne-img/class/digital-learning.png" width="46">
                        </div>
                        <div>
                            <!-- <span class="badge badge-primary mb-1">Kode Siswa : {{ detailregister.users_id }}</span> -->
                            <h1 class="header-title">
                                {{ detailregister.fullname }}
                            </h1>
                            <div class="d-block">
                                <div class="d-flex align-items-center"><span class="fe fe-mail mr-2"></span>
                                    <span v-if="detailregister.email">{{ detailregister.email }}</span>
                                    <span v-else>-</span>
                                </div>
                                <div class="d-flex align-items-center"><span class="fe fe-phone mr-2"></span>
                                    <span v-if="detailregister.phone">{{ detailregister.phone }}</span>
                                    <span v-else>-</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow-sm">
                    <div class="card-body py-3">
                        <div class="d-flex">
                            <div class="mr-4 border-right pr-4"><span class="small">Kode Siswa:</span>
                                <div class="d-block font-weight-bold text-sm">{{ detailregister.users_id }}</div>
                            </div>
                            <div class="mr-4 border-right pr-4"><span class="small">Kode Registrasi:</span>
                                <div class="d-block font-weight-bold text-sm">{{ detailregister.selection ? detailregister.kode_registrasi : '-' }}</div>
                            </div>
                            <div class="mr-4 border-right pr-4"><span class="small">Kategori Registrasi:</span>
                                <div class="d-block font-weight-bold text-sm">{{ detailregister.regis_kategori ? detailregister.regis_kategori : '-' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow-sm">
                    <div class="shadow-sm">
                        <div class="d-block">
                            <div class="card-header border-bottom-0 pb-3">
                                <div class="d-flex align-items-center">
                                    <h3 class="card-header-title sliding-fx-1-2">Informasi Bakal Calon Peserta</h3>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body border-top bg-lighter card-body-rounded card-hidden show pt-0">
                            <ul class="nav nav-tabs nav-overflow mb-0 border-0 ml-3 mt-2">
                                <li class="nav-item" v-for="(step, index) in stepRegister" :key="index">
                                    <a @click="changeComponent(step.url)" class="nav-link pb-3" v-bind:class="{'active' : currentTabComponent == step.url }" style="cursor:pointer;" data-toggle="tab" aria-selected="true">
                                        <span class="notification-dot left"></span>{{ step.title }}
                                    </a>
                                </li>
                                <li v-if="$beta_cicilan && detailregister.status_cicilan == 1 && detailregister.status_registrasi == 'LOLOS'" class="nav-item">
                                    <a @click="changeCicilan()" class="nav-link pb-3" v-bind:class="{'active' : currentTabComponent == 'cicilan' }" style="cursor:pointer;" data-toggle="tab" aria-selected="true">
                                        <span class="notification-dot left"></span>Cicilan
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div class="card m-0" style="box-shadow:none!important;">
                                    <keep-alive>
                                        <component :is="currentComponent"></component>
                                    </keep-alive>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import pendaftaran from "@/components/admin/StepPendaftaran.vue"
    import pendaftaranBeta from "@/components/admin/StepPendaftaranBeta.vue"
    import pembayaran from "@/components/admin/StepPembayaran.vue"
    import seleksi from "@/components/admin/StepTesting.vue"
    import tinjauan from "@/components/admin/StepDaftarUlang.vue"
    import cicilan from "@/components/admin/StepCicilan.vue"

    export default {
        components: {
            pendaftaran,
            pendaftaranBeta,
            pembayaran,
            seleksi,
            tinjauan,
            cicilan
        },
        data() {
            return {
                currentTabComponent: 'pendaftaran',
                currentComponent: 'pendaftaran',
                baseUrl: this.$apiconfig,
                fetching: true,
                detailregister: {
                    id: '',
                    kode_registrasi: '',
                    setup_formulir_id: '',
                    users_id: '',
                    fullname: '',
                    email: '',
                    phone: '',
                    step_position: '',
                    status_pendaftaran: '',
                    status_pembayaran: '',
                    status_testing: '',
                    status_daftar_ulang: '',
                    gelombang_id: '',
                    formulir_id: '',
                    kategori: '',
                    regis_kategori: '',
                    status_cicilan: ''
                },
                stepRegister: [],
                dtStepPendaftaran: [],
                dtStepPembayaran: [],
                dtStepTesting: [],
                message_reject: ''
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData() {
                this.fetching = true;
                // GET STEP HISTORY & POSSION AKTIF
                this.$http.get(this.baseUrl + `admin/registrasi/data/positionstep?id=${this.$route.params.id}`)
                    .then((response) => {
                        this.detailregister.step_registrasi_id = response.data.data[0].step_registrasi_id;
                        this.detailregister.status = response.data.data[0].status;
                        this.detailregister.url = response.data.data[0].url;
                        this.currentTabComponent = response.data.data[0].url;
                        this.currentComponent = response.data.data[0].url;

                        if(this.$beta_custom_form) {
                            if(this.currentComponent == 'pendaftaran') {
                                this.currentTabComponent = 'pendaftaranBeta';
                                this.currentComponent = 'pendaftaranBeta';
                            } else {
                                this.currentTabComponent = response.data.data[0].url;
                                this.currentComponent = response.data.data[0].url;
                            }
                        }

                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                // GET DATA REGISTER
                this.$http.get(this.baseUrl + `admin/registrasi/data/detail?id=${this.$route.params.id}`)
                    .then((response) => {
                        this.detailregister.id = response.data.data[0].id; // tb Registrasi
                        this.detailregister.users_id = response.data.data[0].users_id; // tb Registrasi
                        this.detailregister.kode_registrasi = response.data.data[0].kode_registrasi; // tb Registrasi
                        this.detailregister.setup_formulir_id = response.data.data[0].setup_formulir_id; // tb Registrasi
                        this.detailregister.gelombang_id = response.data.data[0].gelombang_id; // tb Setup Formulir
                        this.detailregister.formulir_id = response.data.data[0].formulir_id; // tb Setup Formulir
                        this.detailregister.url_tes = response.data.data[0].url_tes; // tb Setup Formulir
                        this.detailregister.jadwal_tes = response.data.data[0].jadwal_tes; // tb Setup Formulir
                        this.detailregister.payment_gateway_information = response.data.data[0].payment_gateway_information; // tb Setup Formulir
                        this.detailregister.external_gateway_information = response.data.data[0].external_gateway_information; // tb Setup Formulir
                        this.detailregister.informasi_tes = response.data.data[0].informasi_tes; // tb Setup Formulir
                        this.detailregister.informasi_reg_ulang = response.data.data[0].informasi_reg_ulang; // tb Setup Formulir
                        this.detailregister.deskripsi_formulir = response.data.data[0].deskripsi_formulir; // tb Setup Formulir
                        this.detailregister.deskripsi_lulus = response.data.data[0].deskripsi_lulus; // tb Setup Formulir
                        this.detailregister.deskripsi_tidak_lulus = response.data.data[0].deskripsi_tidak_lulus; // tb Setup Formulir
                        this.detailregister.kode_formulir = response.data.data[0].kode_formulir; // tb Master Formulir
                        this.detailregister.nama_formulir = response.data.data[0].nama_formulir; // tb Master Formulir
                        this.detailregister.unit = response.data.data[0].unit; // tb Master Formulir
                        this.detailregister.keterangan = response.data.data[0].keterangan; // tb Master Formulir
                        this.detailregister.fullname = response.data.data[0].fullname; // tb Users
                        this.detailregister.email = response.data.data[0].email; // tb Users
                        this.detailregister.phone = response.data.data[0].phone; // tb Users
                        this.detailregister.kategori = response.data.data[0].kategori; // tb Users
                        this.detailregister.regis_kategori = response.data.data[0].regis_kategori; // tb Users
                        this.detailregister.status_cicilan = response.data.data[0].status_cicilan;
                        this.detailregister.status_registrasi = response.data.data[0].status_registrasi;
                        this.detailregister.selection = response.data.data[0].selection;
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                // GET STEP SETUP FORMULIR 
                this.$http.get(this.$apiconfig + `admin/registrasi/data/step-registrasi?id=${this.$route.params.id}`)
                    .then((response) => {
                        this.stepRegister = response.data.data;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            changeComponent: function(activeTabs="") {
                this.currentTabComponent = activeTabs;
                if(this.$beta_custom_form) {
                    if(activeTabs == 'pendaftaran') {
                        activeTabs = pendaftaranBeta;
                    }
                }
                this.currentComponent = activeTabs;
            },
            changeCicilan: function() {
                this.currentTabComponent = cicilan;
                this.currentComponent = cicilan;
            }
        }
    }
</script>