<template>
    <div>
        <!-- MODALS Keterangan Penolakan ================================================== -->
        <div class="d-block">
            <div class="card-body">
                <div v-if="status.statusPendaftaran == false" class="alert alert-light shadow m-0">
                    <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 mr-4"><span class="fe fe-alert-triangle h2 m-0"></span></div>
                        <div>Calon peserta belum menyelesaikan semua registrasi sebelumya.</div>
                    </div>
                </div>
                <div v-else>
                    <div class="form-group">
                        <div v-html="detailregister.informasi_tes"></div>
                    </div>
                    <div v-if="status.statuSstep == 'rejected'" class="alert alert-danger shadow mb-4 mt-4">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 mr-4"><span class="fe fe-x-circle h2 m-0"></span></div>
                            <div>{{ status.statusPendaftaran }}.</div>
                        </div>
                    </div>
                    <div v-if="status.statuSstep == 'applied'" class="alert alert-success shadow mb-4 mt-4">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 mr-4"><span class="fe fe-check-circle h2 m-0"></span></div>
                            <div> {{ status.statusPendaftaran }}.</div>
                        </div>
                    </div>
                    <div v-if="detaiPositon.status == 'pending' && detaiPositon.url == 'seleksi'" class="border-top pt-4">
                        <div v-if="status.statuSstep == 'pending'" class="alert alert-info shadow mb-4 mt-4">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 mr-4">
                                    <span class="fe fe-alert-triangle h2"></span>
                                </div>
                                <div>{{ status.statusPendaftaran }}.</div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between d-flex-mobile">
                            <div class="rounded p-3 bg-light">
                                <div class="d-flex align-items-center">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" id="switchOne" v-model="sendbyEmail" @change="sendmail()" class="custom-control-input">
                                        <label for="switchOne" class="custom-control-label"></label>
                                    </div>
                                    <label class="font-weight-bold my-0 ml-2">Kirim Notifikasi Email</label>
                                </div>
                            </div>
                            <div>
                                <button @click.prevent="validasi_testing('lulus')" type="button" class="btn btn-primary mr-2 px-md-4 btn-rounded">
                                    Lulus
                                </button>
                                <button @click.prevent="validasi_testing('tdklulus')" type="button" class="btn btn-danger px-md-4 btn-rounded">
                                    Tidak Lulus
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                baseUrl: this.$apiconfig,
                fetching: true,
                detaiPositon: {
                    step_registrasi_id: '',
                    status: '',
                    url: ''
                },
                detailregister: {
                    id: '',
                    kode_registrasi: '',
                    setup_formulir_id: '',
                    users_id: '',
                    fullname: '',
                    email: '',
                    phone: '',
                    status_pendaftaran: '',
                    status_pembayaran: '',
                    status_testing: '',
                    status_daftar_ulang: '',
                    gelombang_id: '',
                    formulir_id: '',
                    status: '',
                    url: '',
                    informasi_tes: ''
                },
                stepRegister: [],
                dtStepTesting: [],
                message_reject: '',
                sendbyEmail: true,
                statusSendbyEmail: '',
                status: {
                    statusPendaftaran: '',
                    statuSstep: '',
                },
            }
        },
        created() {
            this.getData();
        },
        methods: {
            hide_modal() {
                this.$modal.hide('my-modal-testing')
            },
            getData() {
                this.statusSendbyEmail = "Iya"
                this.fetching = true;
                // GET STEP HISTORY & POSSION AKTIF
                this.$http.get(this.baseUrl + `admin/registrasi/data/positionstep?id=${this.$route.params.id}`)
                    .then((response) => {
                        this.detaiPositon.step_registrasi_id = response.data.data[0].step_registrasi_id;
                        this.detaiPositon.status = response.data.data[0].status;
                        this.detaiPositon.url = response.data.data[0].url;
                        this.currentTabComponent = response.data.data[0].url;
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                // GET DATA REGISTER
                this.$http.get(this.baseUrl + `admin/registrasi/data/detail?id=${this.$route.params.id}`)
                    .then((response) => {
                        this.detailregister.id = response.data.data[0].id; // tb Registrasi
                        this.detailregister.users_id = response.data.data[0].users_id; // tb Registrasi
                        this.detailregister.kode_registrasi = response.data.data[0].kode_registrasi; // tb Registrasi
                        this.detailregister.setup_formulir_id = response.data.data[0].setup_formulir_id; // tb Registrasi
                        this.detailregister.gelombang_id = response.data.data[0].gelombang_id; // tb Setup Formulir
                        this.detailregister.formulir_id = response.data.data[0].formulir_id; // tb Setup Formulir
                        this.detailregister.url_tes = response.data.data[0].url_tes; // tb Setup Formulir
                        this.detailregister.jadwal_tes = response.data.data[0].jadwal_tes; // tb Setup Formulir
                        this.detailregister.payment_gateway_information = response.data.data[0].payment_gateway_information; // tb Setup Formulir
                        this.detailregister.external_gateway_information = response.data.data[0].external_gateway_information; // tb Setup Formulir
                        this.detailregister.informasi_tes = response.data.data[0].informasi_tes; // tb Setup Formulir
                        this.detailregister.informasi_reg_ulang = response.data.data[0].informasi_reg_ulang; // tb Setup Formulir
                        this.detailregister.deskripsi_formulir = response.data.data[0].deskripsi_formulir; // tb Setup Formulir
                        this.detailregister.deskripsi_lulus = response.data.data[0].deskripsi_lulus; // tb Setup Formulir
                        this.detailregister.deskripsi_tidak_lulus = response.data.data[0].deskripsi_tidak_lulus; // tb Setup Formulir
                        this.detailregister.kode_formulir = response.data.data[0].kode_formulir; // tb Master Formulir
                        this.detailregister.nama_formulir = response.data.data[0].nama_formulir; // tb Master Formulir
                        this.detailregister.unit = response.data.data[0].unit; // tb Master Formulir
                        this.detailregister.keterangan = response.data.data[0].keterangan; // tb Master Formulir
                        this.detailregister.fullname = response.data.data[0].fullname; // tb Users
                        this.detailregister.email = response.data.data[0].email; // tb Users
                        this.detailregister.phone = response.data.data[0].phone; // tb Users
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                // GET DATA FORMULIR PENDAFTARAN STEP
                this.$http.get(this.$apiconfig + `admin/registrasi/data/testing?registrasi_id=${this.$route.params.id}`)
                    .then((response) => {
                        this.status.statusPendaftaran = response.data.statusPendaftaran;
                        this.status.statuSstep = response.data.statuSstep;
                        if (response.data.status == false) {
                            this.dtStepPendaftaran = [];
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            // STEP TESTING
            validasi_testing(message_reject) {
                var fd = new FormData()
                fd.append('registrasi_id', this.detailregister.id);
                fd.append('message_reject', message_reject);
                fd.append('statusSendbyEmail', this.statusSendbyEmail)
                this.$http({
                    url: this.baseUrl + 'admin/registrasi/validasi/testing',
                    method: 'post',
                    data: fd
                }).then((response) => {
                    if (response.data.status == true) {
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: response.data.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        this.$swal({
                            icon: 'info',
                            title: "info",
                            text: response.data.message,
                            showConfirmButton: true,
                        })
                    }
                    this.getData();
                }).catch(error => {
                    console.log(error.response.data)
                })
            },
            // SEND EMAIL
            sendmail() {
                if (this.sendbyEmail == true) {
                    this.statusSendbyEmail = "Iya";
                } else {
                    this.statusSendbyEmail = "Tidak";
                }
            }
        }
    }
</script>