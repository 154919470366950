<template>
    <div>
        <!-- MODALS Keterangan Penolakan ================================================== -->
        <modal name="my-modal-pembayaran" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h4 class="card-header-title">
                            Keterangan Penolakan
                        </h4>
                        <!-- Close -->
                        <button type="button" class="close" @click="hide_modal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="card-body">
                            <div class="form-group">
                                <label>Status<span class="text-danger">*</span></label>
                                <select class="form-control" v-model="form.statusRejected" required>
                                    <option value="">Pilih</option>
                                    <option value="rejected" selected>Rejected</option>
                                    <option value="re-check">Re-Check</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Keterangan<span class="text-danger">*</span></label>
                                <textarea v-model="form.message_reject" class="form-control" cols="30" rows="7" required></textarea>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal & Tutup</button>
                                <button @click.prevent="validasi_pembayaran_reject()" type="button" class="btn btn-primary mr-2">Simpan</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <!-- CONTENT -->
        <div class="d-block">
            <div class="card-body">
                <div v-if="dtStepPembayaran.length == 0">
                    <div v-if="status.statuSstep == 'pending'" class="alert alert-light shadow">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 mr-4">
                                <span class="fe fe-alert-triangle h2"></span>
                            </div>
                            <div>{{ status.statusPendaftaran }}.</div>
                        </div>
                    </div>
                    <div v-else class="alert alert-light shadow m-0">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 mr-4">
                                <span class="fe fe-alert-triangle h2 m-0"></span>
                            </div>
                            <div>Calon peserta belum menyelesaikan semua registrasi sebelumya.</div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="table-responsive border rounded">
                        <table class="table table-nowrap card-table">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Tanggal</th>
                                    <th>Nominal</th>
                                    <th>Method</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="list">
                                <tr v-for="(dtPembayaran, index) in dtStepPembayaran" :key="index">
                                    <td>
                                        <span v-if="dtPembayaran.status == 'settlement'" class="px-3 py-2 bg-success text-success rounded small text-capitalize"><span class="fe fe-check-circle mr-2"></span>{{ dtPembayaran.status }}</span>
                                        <span v-if="dtPembayaran.status == 'cancel'" class="px-3 py-2 bg-danger text-white rounded small text-capitalize"><span class="fe fe-x-circle mr-2"></span>Cancel</span>
                                        <span v-if="dtPembayaran.status == 'pending'" class="px-3 py-2 bg-warning text-warning rounded small text-capitalize"><span class="fe fe-clock mr-2"></span>Pending</span>
                                        <span v-if="dtPembayaran.status == 'refund'" class="px-3 py-2 bg-info text-white rounded small text-capitalize"><span class="fe fe-refresh-ccw mr-2"></span>Refund</span>
                                        <span v-if="dtPembayaran.status == 'expire'" class="px-3 py-2 bg-danger text-white rounded small text-capitalize"><span class="fe fe-x-circle mr-2"></span>Expired</span>
                                    </td>
                                    <td class="w-100">{{ dtPembayaran.tanggal_pembayaran }}</td>
                                    <td>{{ dtPembayaran.nominal }}</td>
                                    <td class="text-capitalize">{{ dtPembayaran.metode_pembayaran }}</td>
                                    <td>
                                        <!-- <a :href="dtPembayaran.bukti_transfer" target="_blank" class="btn btn-sm btn-outline-primary mr-2">
                                            Cek Bukti Pembayaran
                                        </a> -->
                                        <button class="btn btn-sm btn-outline-primary mr-2" data-toggle="modal" v-bind:data-target="'#cekBuktiPembayaran'+dtPembayaran.id">
                                            Cek Bukti Pembayaran
                                        </button>
                                    </td>
                                    <div class="modal fade" height="auto" v-bind:id="'cekBuktiPembayaran'+dtPembayaran.id" tabindex="-1" role="dialog" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered" role="document">
                                            <div class="modal-content">
                                                <div class="modal-card card">
                                                    <div class="card-header">
                                                        <h4 class="card-header-title" id="exampleModalCenterTitle">
                                                            Bukti Pembayaran
                                                        </h4>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>

                                                    </div>
                                                    <div class="card-body" style="height: 1000px;">
                                                        <div v-if="dtPembayaran.bukti_transfer.split('.').pop() == 'pdf'" style="height: 100%">
                                                            <embed v-bind:src="dtPembayaran.bukti_transfer" alt="..." class="avatar-img rounded">
                                                        </div>
                                                        <div v-else>
                                                            <img v-bind:src="dtPembayaran.bukti_transfer" alt="..." class="avatar-img rounded">
                                                        </div>
                                                    </div>
                                                    <div class="card-footer border-top-0">
                                                        <ul class="pagination mb-0"></ul>
                                                        <div class="d-flex justify-content-end">
                                                            <a @click.prevent="download(dtPembayaran.bukti_transfer,'BUKTI-BAYAR')" class="btn btn-sm btn-white d-none d-md-inline-block"> Download </a>
                                                            <a href="#!" class="btn btn-sm btn-white d-none d-md-inline-block" data-dismiss="modal" aria-label="Close"> Tutup</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <div v-if="status.statuSstep == 'waiting'" class="alert alert-warning shadow mb-4 mt-4">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 mr-4">
                                    <span class="fe fe-alert-triangle h2"></span>
                                </div>
                                <div>{{ status.statusPendaftaran }}.</div>
                            </div>
                        </div>
                        <div v-if="status.statuSstep == 're-check'" class="alert alert-info shadow mb-4 mt-4">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 mr-4">
                                    <span class="fe fe-alert-triangle h2"></span>
                                </div>
                                <div>{{ status.statusPendaftaran }}.</div>
                            </div>
                        </div>
                        <div v-if="status.statuSstep == 'rejected'" class="alert alert-danger shadow mb-4 mt-4">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 mr-4"><span class="fe fe-x-circle h2 m-0"></span></div>
                                <div>{{ status.statusPendaftaran }}.</div>
                            </div>
                        </div>
                        <div v-if="status.statuSstep == 'applied'" class="alert alert-success shadow mb-4 mt-4">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 mr-4"><span class="fe fe-check-circle h2 m-0"></span></div>
                                <div>{{ status.statusPendaftaran }}.</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="detaiPositon.status == 'waiting' || detaiPositon.status == 're-check'">
                        <div class="form-group mb-0" v-if="detaiPositon.url == 'pembayaran'">
                            <div class="border rounded p-3 mb-4">
                                <div class="d-flex align-items-center">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" id="switchOne" v-model="sendbyEmail" @change="sendmail()" class="custom-control-input">
                                        <label for="switchOne" class="custom-control-label"></label>
                                    </div>
                                    <label class="font-weight-bold my-0 ml-2">kirim notifikasi email</label>
                                </div>
                            </div>
                            <button @click.prevent="validasi_pembayaran()" type="button" class="btn btn-primary mr-2">
                                Approve
                            </button>
                            <button @click.prevent="reject_pembayaran()" type="button" class="btn btn-danger">
                                Reject
                            </button>
                        </div>
                        <div v-else>
                        </div>
                    </div>
                    <div v-else>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                baseUrl: this.$apiconfig,
                fetching: true,
                detaiPositon: {
                    step_registrasi_id: '',
                    status: '',
                    url: ''
                },
                detailregister: {
                    id: '',
                    kode_registrasi: '',
                    setup_formulir_id: '',
                    users_id: '',
                    fullname: '',
                    email: '',
                    phone: '',
                    status_pendaftaran: '',
                    status_pembayaran: '',
                    status_testing: '',
                    status_daftar_ulang: '',
                    gelombang_id: '',
                    formulir_id: '',
                },
                form: {
                    message_reject: '',
                    statusRejected: '',
                },
                dtStepPembayaran: [],
                sendbyEmail: true,
                statusSendbyEmail: '',
                status: {
                    statusPendaftaran: '',
                    statuSstep: '',
                },
            }
        },
        created() {
            this.getData();
        },
        methods: {
            hide_modal() {
                this.$modal.hide('my-modal-pembayaran')
            },
            getData() {
                this.statusSendbyEmail = "Iya"
                this.fetching = true;
                // GET STEP HISTORY & POSSION AKTIF
                this.$http.get(this.baseUrl + `admin/registrasi/data/positionstep?id=${this.$route.params.id}`)
                    .then((response) => {
                        this.detaiPositon.step_registrasi_id = response.data.data[0].step_registrasi_id;
                        this.detaiPositon.status = response.data.data[0].status;
                        this.detaiPositon.url = response.data.data[0].url;
                        this.currentTabComponent = response.data.data[0].url;
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                // GET DATA REGISTER
                this.$http.get(this.baseUrl + `admin/registrasi/data/detail?id=${this.$route.params.id}`)
                    .then((response) => {
                        this.detailregister.id = response.data.data[0].id; // tb Registrasi
                        this.detailregister.users_id = response.data.data[0].users_id; // tb Registrasi
                        this.detailregister.kode_registrasi = response.data.data[0].kode_registrasi; // tb Registrasi
                        this.detailregister.setup_formulir_id = response.data.data[0].setup_formulir_id; // tb Registrasi
                        this.detailregister.gelombang_id = response.data.data[0].gelombang_id; // tb Setup Formulir
                        this.detailregister.formulir_id = response.data.data[0].formulir_id; // tb Setup Formulir
                        this.detailregister.url_tes = response.data.data[0].url_tes; // tb Setup Formulir
                        this.detailregister.jadwal_tes = response.data.data[0].jadwal_tes; // tb Setup Formulir
                        this.detailregister.payment_gateway_information = response.data.data[0].payment_gateway_information; // tb Setup Formulir
                        this.detailregister.external_gateway_information = response.data.data[0].external_gateway_information; // tb Setup Formulir
                        this.detailregister.informasi_tes = response.data.data[0].informasi_tes; // tb Setup Formulir
                        this.detailregister.informasi_reg_ulang = response.data.data[0].informasi_reg_ulang; // tb Setup Formulir
                        this.detailregister.deskripsi_formulir = response.data.data[0].deskripsi_formulir; // tb Setup Formulir
                        this.detailregister.deskripsi_lulus = response.data.data[0].deskripsi_lulus; // tb Setup Formulir
                        this.detailregister.deskripsi_tidak_lulus = response.data.data[0].deskripsi_tidak_lulus; // tb Setup Formulir
                        this.detailregister.kode_formulir = response.data.data[0].kode_formulir; // tb Master Formulir
                        this.detailregister.nama_formulir = response.data.data[0].nama_formulir; // tb Master Formulir
                        this.detailregister.unit = response.data.data[0].unit; // tb Master Formulir
                        this.detailregister.keterangan = response.data.data[0].keterangan; // tb Master Formulir
                        this.detailregister.fullname = response.data.data[0].fullname; // tb Users
                        this.detailregister.email = response.data.data[0].email; // tb Users
                        this.detailregister.phone = response.data.data[0].phone; // tb Users
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                // GET DATA PEMBAYARAN STEP
                this.$http.get(this.$apiconfig + `admin/registrasi/data/pembayaran?registrasi_id=${this.$route.params.id}`)
                    .then((response) => {
                        this.dtStepPembayaran = response.data.data;
                        this.status.statusPendaftaran = response.data.statusPendaftaran;
                        this.status.statuSstep = response.data.statuSstep;
                        this.fetching = false;
                        if (response.data.status == false) {
                            this.dtStepPembayaran = [];
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            // STEP PEMBAYARAN
            reject_pembayaran() {
                this.$modal.show('my-modal-pembayaran');
            },
            // VALIDASI PENDAFTARAN JIKA SETUJUH
            validasi_pembayaran() {
                var fd = new FormData()
                fd.append('registrasi_id', this.detailregister.id);
                fd.append('message_reject', '');
                fd.append('statusRejected', '');
                fd.append('step_registrasi_id', this.detaiPositon.step_registrasi_id);
                fd.append('statusSendbyEmail', this.statusSendbyEmail)
                this.$http({
                    url: this.baseUrl + 'admin/registrasi/validasi/pembayaran',
                    method: 'post',
                    data: fd
                }).then((response) => {
                    if (response.data.status == true) {
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: response.data.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        this.$swal({
                            icon: 'info',
                            title: "info",
                            text: response.data.message,
                            showConfirmButton: true,
                        })
                    }
                    this.$modal.hide('my-modal-pembayaran')
                    this.detaiPositon.status = 'applied';
                    this.form.message_reject = '';
                    this.form.statusRejected = '';
                    this.getData();
                }).catch(error => {
                    console.log(error.response.data)
                })
            },
            // VALIDASI PENDAFTARAN JIKA TIDAK SETUJUH
            validasi_pembayaran_reject() {
                if (this.form.statusRejected == '') {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Status reject boleh kosong',
                    })
                } else {
                    var fd = new FormData()
                    fd.append('registrasi_id', this.detailregister.id);
                    fd.append('message_reject', this.form.message_reject);
                    fd.append('statusRejected', this.form.statusRejected);
                    fd.append('step_registrasi_id', this.detaiPositon.step_registrasi_id);
                    fd.append('statusSendbyEmail', this.statusSendbyEmail)
                    this.$http({
                        url: this.baseUrl + 'admin/registrasi/validasi/pembayaran',
                        method: 'post',
                        data: fd
                    }).then((response) => {
                        if (response.data.status == true) {
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        } else {
                            this.$swal({
                                icon: 'info',
                                title: "info",
                                text: response.data.message,
                                showConfirmButton: true,
                            })
                        }
                        this.$modal.hide('my-modal-pembayaran')
                        this.detaiPositon.status = 'rejected';
                        this.form.message_reject = '';
                        this.form.statusRejected = '';
                        this.getData();
                    }).catch(error => {
                        console.log(error.response.data)
                    })
                }
            },
            // SEND EMAIL
            sendmail() {
                if (this.sendbyEmail == true) {
                    this.statusSendbyEmail = "Iya";
                } else {
                    this.statusSendbyEmail = "Tidak";
                }
            },
            // DOWNLOAD
            download(url, filename) {
                fetch(url)
                    .then(response => response.blob())
                    .then(blob => {
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = filename;
                        link.click();
                    })
                    .catch(console.error);
            }
        }
    }
</script>